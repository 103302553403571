import React from "react";
import { AuthProvider as OAuthProvider, TAuthConfig, TRefreshTokenExpiredEvent } from "react-oauth2-code-pkce";
import { AuthWithUserProvider } from "./auth-with-user-provider";

const authConfig = {
  clientId: "CID-B017C86A4EE54879A25E9D14FC8B6D2E",
  authorizationEndpoint: "auth/authorization",
  tokenEndpoint: "https://account.garant.ru/oauth2/token",
  redirectUri: window.location.origin + "/auth/callback/garant",
  logoutEndpoint: "auth/logout",
  onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) => event.logIn(undefined, undefined, "popup"),
  autoLogin: false,
  state: `${Math.random().toString(36).substring(7)}_${Date.now()}`,
  scope: "offline openid",
} satisfies TAuthConfig;

export function AuthProvider({ children }: { children?: React.ReactNode }): React.JSX.Element {
  return (
    <OAuthProvider authConfig={authConfig}>
      <AuthWithUserProvider>{children}</AuthWithUserProvider>
    </OAuthProvider>
  );
}
