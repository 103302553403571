import { useState } from "react";
import { SelectedDocument } from "../model/selected-document";

export function useDocuments() {
  const [documents, setDocument] = useState<SelectedDocument[]>([]);
  const clearDocuments = () => {
    setDocument([]);
  };

  return { documents, setDocument, clearDocuments };
}
