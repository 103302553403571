import React from "react";
import { Button, Card, makeStyles, shorthands, typographyStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...typographyStyles.body1Strong,
    ...shorthands.textDecoration("none"),
    ...shorthands.gap("8px"),
  },

  content: {},
});

export function ChoiceLink({
  content,
  status,
  onSelect,
  onRemove,
}: {
  content: string;
  status?: React.ReactNode;
  onSelect: () => void;
  onRemove: () => void;
}): React.JSX.Element {
  const styles = useStyles();
  return (
    <Card className={styles.root}>
      <div className={styles.content}>{content}</div>
      {status}
      <Button onClick={onSelect}>{status === undefined ? "Выбрать ссылку вручную" : "Изменить выбор"}</Button>
      <Button appearance="subtle" onClick={onRemove}>
        {"Удалить"}
      </Button>
    </Card>
  );
}
