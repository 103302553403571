import React from "react";
import { Loader } from "@taskpane/shared/ui";
import { usePublicLinks, useLinksManager } from "@taskpane/plugin/links";
import { DocumentList } from "@taskpane/plugin/links/ui/document-list";
import { FilteredLinkList } from "@taskpane/plugin/links/ui/filtered-link-list";
import { Container, BackNavigation } from "@taskpane/shared/layout";

export function PublicUnknownLinks(): React.JSX.Element {
  const { data = [], isLoading } = usePublicLinks();
  const {
    filteredLinks,
    link,
    documents,
    chooseDocument,
    cancelChooseDocument,
    chooseLink,
    removeLink,
    setFilterCriteria,
  } = useLinksManager(data);

  if (isLoading) {
    return <Loader />;
  }

  if (link !== null) {
    return (
      <>
        <BackNavigation title={"К списку ссылок"} onClick={cancelChooseDocument} />
        <Container>
          <DocumentList link={link} selectedDocuments={documents} chooseDocument={chooseDocument} />
        </Container>
      </>
    );
  }

  return (
    <Container>
      <FilteredLinkList
        links={filteredLinks}
        documents={documents}
        chooseLink={chooseLink}
        removeLink={removeLink}
        setFilterCriteria={setFilterCriteria}
      />
    </Container>
  );
}
