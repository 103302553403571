import React from "react";
import {
  Button,
  Dialog as FluentDialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTrigger,
  makeStyles,
  shorthands,
  tokens,
  typographyStyles,
} from "@fluentui/react-components";
import { More } from "@taskpane/shared/ui";

type Props = {
  content: string;
};

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "space-between",
    ...shorthands.borderRadius("4px", "4px", "0px", "0px"),
    ...typographyStyles.body1Strong,
  },

  icon: {
    cursor: "pointer",
    ...shorthands.textDecoration("none"),
    ...shorthands.padding(tokens.spacingVerticalXS, tokens.spacingHorizontalXS),
  },
});

export function Dialog({ content }: Props): React.JSX.Element {
  const styles = useStyles();
  return (
    <FluentDialog>
      <DialogTrigger disableButtonEnhancement>
        <More className={styles.icon} />
      </DialogTrigger>
      <DialogSurface className={styles.root}>
        <DialogBody>
          <DialogContent>{content}</DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Закрыть</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </FluentDialog>
  );
}
