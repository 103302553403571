import { Url, UrlParser, InvalidUrlError, UrlParsingError } from "@taskpane/shared/url-parser";

export class UuidParamParser implements UrlParser {
  private readonly ALLOWED_HOST = "base.garant.ru";

  private isValidUuid(uuid: string | null): boolean {
    if (!uuid) return false;
    const [documentId, entityId] = uuid.split(".");
    return !!documentId && !!entityId && !isNaN(Number(documentId)) && !isNaN(Number(entityId));
  }

  isAllow(url: string): boolean {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.hostname === this.ALLOWED_HOST && this.isValidUuid(parsedUrl.searchParams.get("uuid"));
    } catch (e) {
      return false;
    }
  }

  parse(url: string): Url {
    if (!this.isAllow(url)) {
      throw new InvalidUrlError();
    }

    const parsedUrl = new URL(url);
    const uuid = parsedUrl.searchParams.get("uuid");

    if (!uuid) {
      throw new UrlParsingError("UUID is missing in the URL");
    }

    return {
      uuid,
    };
  }
}
