import React from "react";
import { bundleIcon, FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

const Icon = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.83585 1.03398C8.94837 1.07226 9.05829 1.11779 9.16492 1.17028L10.4477 1.80183C10.7958 1.97323 11.2038 1.97323 11.5519 1.80183L12.8347 1.17028C14.1972 0.499421 15.8457 1.06018 16.5165 2.42276L16.59 2.58509L16.6528 2.75183L17.1133 4.10543C17.2383 4.4728 17.5268 4.7613 17.8941 4.88627L19.2477 5.34673C20.6856 5.83585 21.4547 7.39799 20.9656 8.83585C20.9273 8.94837 20.8818 9.05829 20.8293 9.16492L20.1977 10.4477C20.0263 10.7958 20.0263 11.2038 20.1977 11.5519L20.8293 12.8347C21.5002 14.1972 20.9394 15.8457 19.5768 16.5165C19.4702 16.569 19.3603 16.6146 19.2477 16.6528L17.8941 17.1133C17.5268 17.2383 17.2383 17.5268 17.1133 17.8941L16.6528 19.2477C16.1637 20.6856 14.6016 21.4547 13.1637 20.9656C13.0512 20.9273 12.9413 20.8818 12.8347 20.8293L11.5519 20.1977C11.2038 20.0263 10.7958 20.0263 10.4477 20.1977L9.16492 20.8293C7.80233 21.5002 6.15389 20.9394 5.48303 19.5768C5.43053 19.4702 5.385 19.3603 5.34673 19.2477L4.88627 17.8941C4.7613 17.5268 4.4728 17.2383 4.10543 17.1133L2.75183 16.6528C1.31396 16.1637 0.544855 14.6016 1.03398 13.1637C1.07226 13.0512 1.11779 12.9413 1.17028 12.8347L1.80183 11.5519C1.97323 11.2038 1.97323 10.7958 1.80183 10.4477L1.17028 9.16492C0.499421 7.80233 1.06018 6.15389 2.42276 5.48303C2.52939 5.43053 2.63931 5.385 2.75183 5.34673L4.10543 4.88627C4.4728 4.7613 4.7613 4.4728 4.88627 4.10543L5.34673 2.75183C5.83585 1.31396 7.39799 0.544855 8.83585 1.03398ZM14.4695 7.96946L9.05019 13.3887L7.07595 11.0197C6.81078 10.7014 6.33786 10.6584 6.01965 10.9236C5.70144 11.1888 5.65845 11.6617 5.92362 11.9799L8.42362 14.9799C8.70596 15.3187 9.21827 15.342 9.53012 15.0301L15.5301 9.03012C15.823 8.73722 15.823 8.26235 15.5301 7.96946C15.2372 7.67657 14.7624 7.67657 14.4695 7.96946Z"
        fill="currentColor"
      />
    </svg>
  );
}, "CheckIcon");

export const CheckIcon = bundleIcon(Icon, Icon);
