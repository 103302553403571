import React from "react";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

type Props = {
  children: React.ReactNode;
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingVerticalM),
  },
});

export function Fragments({ children }: Props): React.JSX.Element {
  const styles = useStyles();
  return <div className={styles.root}>{children}</div>;
}
