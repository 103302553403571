export function date(date: Date, format: string, locale: string = "ru"): string {
  const formatOptions: Intl.DateTimeFormatOptions = getFormatOptions(format);
  return date.toLocaleString(locale, formatOptions);
}

function getFormatOptions(format: string): Intl.DateTimeFormatOptions {
  return {
    year: format.includes("yyyy") ? "numeric" : undefined,
    month: getMonthFormat(format),
    day: format.includes("dd") ? "2-digit" : undefined,
    hour: format.includes("HH") ? "2-digit" : undefined,
    minute: format.includes("mm") ? "2-digit" : undefined,
    second: format.includes("ss") ? "2-digit" : undefined,
  };
}

function getMonthFormat(format: string): Intl.DateTimeFormatOptions["month"] {
  if (format.includes("MMMM")) return "long";
  if (format.includes("MM")) return "2-digit";
  return undefined;
}
