import React from "react";
import { bundleIcon, FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

const Icon = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg width="88" height="24" viewBox="0 0 88 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M54.3525 0.36084H36.5586V17.2018H38.7215V2.47247H54.3525V0.36084Z" fill="#0055A2" />
      <path
        d="M75.4409 4.55615V8.80918H72.1756V4.55615H70.0526V15.0901H67.8896V4.55615H50.6166V15.0901H48.4536V4.55615H40.9023V6.66964H46.2793V8.80918H40.9023V17.2017H52.7377V13.0045H58.1849V6.66964H65.7457V8.80918H60.2985V17.2017H72.1756V10.8836H75.4409V17.2017H77.583V6.66964H80.8064V17.2017H82.9903V6.66964H86.2366V4.55615H75.4409ZM46.2812 15.0882H44.6675H43.0539V10.8836H44.6675H46.2812V15.0882ZM55.9935 10.8836H54.3722H52.7396V6.66964H54.3722H55.9935V10.8836ZM65.7476 15.0882H64.0959H62.4538V10.8836H64.0959H65.7476V15.0882Z"
        fill="#0055A2"
      />
      <path d="M68.4086 0.36084H54.9102V2.47247H68.4086V0.36084Z" fill="#E20613" />
      <path d="M75.9605 0.36084H68.9414V2.47247H75.9605V0.36084Z" fill="#E20613" />
      <path d="M84.6217 0.36084H81.8867V2.47247H84.6217V0.36084Z" fill="#E20613" />
      <path d="M86.2327 0.36084H85.1797V2.47247H86.2327V0.36084Z" fill="#E20613" />
      <path d="M81.3665 0.36084H76.5312V2.47247H81.3665V0.36084Z" fill="#E20613" />
      <path
        d="M86.479 8.78125C85.772 8.78125 85.1523 9.38032 85.1523 10.1133C85.1523 10.8073 85.772 11.4157 86.479 11.4157C87.2355 11.4157 87.8551 10.8073 87.8551 10.1133C87.8551 9.38962 87.2355 8.78125 86.479 8.78125ZM86.479 11.1496C85.9373 11.1496 85.4564 10.6733 85.4564 10.1133C85.4564 9.51241 85.9126 9.0473 86.479 9.0473C87.0473 9.0473 87.5111 9.52544 87.5111 10.1133C87.5111 10.6733 87.0682 11.1496 86.479 11.1496Z"
        fill="#0055A2"
      />
      <path
        d="M86.8271 10.1137C86.8936 10.0746 87.0343 10.0077 87.0343 9.80859C87.0343 9.64487 86.9449 9.4458 86.6009 9.4458H86.0117V10.7388H86.3272V10.2439H86.3937C86.4242 10.2439 86.4546 10.2532 86.4831 10.2532C86.829 10.2923 86.561 10.5584 86.7378 10.737H87.0723C87.0039 10.67 87.0343 10.6514 87.0039 10.4542C87.002 10.3407 87.002 10.1807 86.8271 10.1137ZM86.4812 10.0077H86.3253V9.67464H86.4622C86.4622 9.67464 86.7378 9.63371 86.7378 9.80859C86.7359 9.97975 86.5496 10.0077 86.4812 10.0077Z"
        fill="#0055A2"
      />
      <path
        d="M37.1094 17.7247V18.2586H39.7931V3.51074H87.3264V0.893066H86.7866V2.99725H39.2628V17.7247H37.1094Z"
        fill="#0055A2"
      />
      <path
        d="M41.4219 17.7244V18.2584H53.8407V14.0407H59.2462V7.72445H65.1838V7.20166H58.6969V13.5086H53.3085V17.7244H41.4219Z"
        fill="#0055A2"
      />
      <path d="M43.5742 14.5658V11.416H45.7277V11.9314H44.114V14.5658H43.5742Z" fill="#0055A2" />
      <path d="M45.7327 7.20166H41.4258V7.72445H45.7327V7.20166Z" fill="#0055A2" />
      <path d="M49.5147 5.10889H48.9844V14.5656H49.5147V5.10889Z" fill="#0055A2" />
      <path d="M53.3086 7.20166V10.3589H53.8408V7.72445H55.462V7.20166H53.3086Z" fill="#0055A2" />
      <path d="M63.0117 14.5658V11.416H65.1842V11.9314H63.5629V14.5658H63.0117Z" fill="#0055A2" />
      <path d="M68.9502 5.10889H68.418V14.5656H68.9502V5.10889Z" fill="#0055A2" />
      <path d="M73.2745 5.10889H72.7461V8.25865H73.2745V5.10889Z" fill="#0055A2" />
      <path d="M74.8976 11.416H72.7441V17.7249H60.8594V18.2588H73.2744V11.9314H74.8976V11.416Z" fill="#0055A2" />
      <path d="M75.9805 17.7244V18.2584H78.6832V7.72445H80.2949V7.20166H78.1434V17.7244H75.9805Z" fill="#0055A2" />
      <path
        d="M81.3789 17.7247H83.5418V7.20191H86.7862V5.10889H87.326V7.7247H84.0816V18.2587H81.3789V17.7247Z"
        fill="#0055A2"
      />
      <path
        d="M16.0166 0.0146484C12.8311 0.0146484 10.25 2.54302 10.25 5.6593C10.25 8.77744 12.833 11.3058 16.0185 11.3058C19.204 11.3058 21.785 8.7793 21.785 5.6593C21.7831 2.54488 19.2021 0.0146484 16.0166 0.0146484ZM11.0407 5.57558C11.0407 5.57558 10.9171 2.30116 14.2109 1.0286C12.4395 2.67325 11.837 3.1086 11.0407 5.57558Z"
        fill="#0055A2"
      />
      <path
        d="M2.20471 4.02979C5.81594 8.60467 10.6702 11.4103 16.0148 11.4103C21.3594 11.4103 26.2137 8.60467 29.8249 4.02979H31.7484V18.1749H7.92566C22.4694 16.9116 29.2262 6.88188 29.996 4.94327L30.9026 4.95816H29.9504C29.9504 4.95816 24.9403 12.2475 15.724 12.2475C6.50777 12.2475 1.99753 4.95816 1.99753 4.95816C1.44635 4.90606 0.944576 5.00839 0.944576 5.00839V18.1749H0.28125V4.02979H2.20471Z"
        fill="#E20613"
      />
      <path
        d="M0.262289 20.627V22.7088L0.247084 23.0456H0.258488L0.416242 22.7646L1.7695 20.627H2.0698V23.399H1.80561V21.3153L1.82082 20.9786H1.80942L1.66877 21.239L0.300302 23.399H0V20.627H0.262289Z"
        fill="#0055A2"
      />
      <path
        d="M3.13338 20.627V21.8493H4.59308V20.627H4.85727V23.399H4.59308V22.0744H3.13338V23.399H2.87109V20.627H3.13338Z"
        fill="#0055A2"
      />
      <path
        d="M7.10172 20.4595V20.7869H7.22526C8.01783 20.7869 8.45878 21.386 8.45878 21.9869C8.45878 22.5897 8.01783 23.1869 7.22526 23.1869H7.10172V23.5237H6.83753V23.1869H6.71399C5.92142 23.1869 5.48047 22.5897 5.48047 21.9869C5.48047 21.386 5.92142 20.7869 6.71399 20.7869H6.83753V20.4595H7.10172ZM6.83753 21.0139H6.71399C6.1685 21.0139 5.76366 21.3674 5.76366 21.9888C5.76366 22.612 6.1685 22.9637 6.71399 22.9637H6.83753V21.0139ZM7.10172 22.9618H7.22526C7.76885 22.9618 8.17559 22.6102 8.17559 21.9869C8.17559 21.3655 7.77075 21.012 7.22526 21.012H7.10172V22.9618Z"
        fill="#0055A2"
      />
      <path
        d="M10.1681 20.5933C11.0253 20.5933 11.4377 21.2705 11.4377 22.0147C11.4377 22.93 10.8808 23.4286 10.1681 23.4286C9.45533 23.4286 8.89844 22.9319 8.89844 22.0147C8.89844 21.2705 9.31088 20.5933 10.1681 20.5933ZM10.1681 23.2035C10.6565 23.2035 11.1526 22.8463 11.1526 22.0165C11.1526 21.1849 10.6565 20.8202 10.1681 20.8202C9.6796 20.8202 9.18353 21.1849 9.18353 22.0165C9.18353 22.8463 9.6796 23.2035 10.1681 23.2035Z"
        fill="#0055A2"
      />
      <path
        d="M12.7865 20.6269C13.3073 20.6269 13.6285 20.9506 13.6285 21.425C13.6285 21.9013 13.313 22.225 12.7865 22.225H12.3228V23.3971H12.0586V20.625H12.7865V20.6269ZM12.3228 21.9999H12.7352C12.8873 21.9999 13.3434 21.972 13.3434 21.425C13.3434 20.8538 12.8055 20.852 12.7352 20.852H12.3228V21.9999Z"
        fill="#0055A2"
      />
      <path
        d="M15.672 23.1479L15.7899 22.7795L16.7231 20.627H17.1393V23.399H16.8751V21.2521L16.8827 20.8763H16.8656L16.7307 21.239L15.8013 23.399H15.5295L14.6 21.239L14.4632 20.8763H14.448L14.4556 21.2521V23.399H14.1914V20.627H14.6076L15.5409 22.7795L15.6568 23.1479H15.672Z"
        fill="#0055A2"
      />
      <path
        d="M19.0575 20.627L20.2397 23.399H19.9546L19.6296 22.6344H18.1965L17.8848 23.399H17.6016L18.7134 20.627H19.0575ZM18.8769 20.8837L18.7895 21.1442L18.2801 22.4074H19.5345L18.989 21.1442L18.8883 20.8837H18.8769Z"
        fill="#0055A2"
      />
      <path
        d="M20.9634 20.627V23.1721H22.4535V20.627H22.7177V23.1721H23.0503V24H22.7861V23.399H20.6992V20.627H20.9634Z"
        fill="#0055A2"
      />
      <path
        d="M23.8755 20.627V22.7088L23.8584 23.0456H23.8698L24.0275 22.7646L25.3808 20.627H25.6811V23.399H25.4169V21.3153L25.4321 20.9786H25.4207L25.28 21.239L23.9097 23.399H23.6094V20.627H23.8755Z"
        fill="#0055A2"
      />
      <path
        d="M27.5743 20.5933C28.4315 20.5933 28.844 21.2705 28.844 22.0147C28.844 22.93 28.2871 23.4286 27.5743 23.4286C26.8616 23.4286 26.3047 22.9319 26.3047 22.0147C26.3028 21.2705 26.7171 20.5933 27.5743 20.5933ZM27.5743 23.2035C28.0628 23.2035 28.5589 22.8463 28.5589 22.0165C28.5589 21.1849 28.0628 20.8202 27.5743 20.8202C27.0859 20.8202 26.5879 21.1849 26.5879 22.0165C26.5879 22.8463 27.084 23.2035 27.5743 23.2035Z"
        fill="#0055A2"
      />
      <path
        d="M29.7251 20.627V21.8493H31.1848V20.627H31.4471V23.399H31.1848V22.0744H29.7251V23.399H29.4609V20.627H29.7251Z"
        fill="#0055A2"
      />
      <path
        d="M32.5142 20.627V21.8493H33.972V20.627H34.2362V23.399H33.972V22.0744H32.5142V23.399H32.25V20.627H32.5142Z"
        fill="#0055A2"
      />
      <path
        d="M36.129 20.5933C36.9862 20.5933 37.3986 21.2705 37.3986 22.0147C37.3986 22.93 36.8418 23.4286 36.129 23.4286C35.4163 23.4286 34.8594 22.9319 34.8594 22.0147C34.8594 21.2705 35.2718 20.5933 36.129 20.5933ZM36.129 23.2035C36.6175 23.2035 37.1135 22.8463 37.1135 22.0165C37.1135 21.1849 36.6175 20.8202 36.129 20.8202C35.6405 20.8202 35.1426 21.1849 35.1426 22.0165C35.1426 22.8463 35.6386 23.2035 36.129 23.2035Z"
        fill="#0055A2"
      />
      <path d="M38.8995 21.9629H38.0195V22.1731H38.8995V21.9629Z" fill="#0055A2" />
      <path d="M41.6892 20.627V23.399H41.425V20.8521H39.9634V23.399H39.6992V20.627H41.6892Z" fill="#0055A2" />
      <path
        d="M43.2201 20.6269C43.7409 20.6269 44.0602 20.9506 44.0602 21.425C44.0602 21.9013 43.7447 22.225 43.2201 22.225H42.7564V23.3971H42.4922V20.625H43.2201V20.6269ZM42.7564 21.9999H43.1688C43.3209 21.9999 43.777 21.972 43.777 21.425C43.777 20.8538 43.2391 20.852 43.1688 20.852H42.7564V21.9999Z"
        fill="#0055A2"
      />
      <path
        d="M45.739 20.627L46.9213 23.399H46.6362L46.313 22.6344H44.8781L44.5663 23.399H44.2812L45.395 20.627H45.739ZM45.5604 20.8837L45.4711 21.1442L44.9617 22.4074H46.2161L45.6706 21.1442L45.5718 20.8837H45.5604Z"
        fill="#0055A2"
      />
      <path
        d="M48.1317 20.627C48.7152 20.627 48.9527 20.947 48.9527 21.3228C48.9527 21.5832 48.833 21.8288 48.4662 21.9497V21.9609C48.8026 22.0242 49.0326 22.2884 49.0326 22.6177C49.0326 23.3916 48.24 23.399 48.107 23.399H47.3828V20.627H48.1317ZM47.647 21.853H48.0956C48.4681 21.853 48.6696 21.6279 48.6696 21.3265C48.6696 20.9897 48.413 20.8521 48.1051 20.8521H47.6489V21.853H47.647ZM47.647 23.1721H48.0708C48.3084 23.1721 48.7475 23.1293 48.7475 22.6214C48.7475 22.2772 48.4757 22.08 48.0994 22.08H47.647V23.1721Z"
        fill="#0055A2"
      />
      <path
        d="M50.7833 20.5933C51.6404 20.5933 52.0529 21.2705 52.0529 22.0147C52.0529 22.93 51.496 23.4286 50.7833 23.4286C50.0705 23.4286 49.5117 22.9319 49.5117 22.0147C49.5117 21.2705 49.9261 20.5933 50.7833 20.5933ZM50.7833 23.2035C51.2717 23.2035 51.7678 22.8463 51.7678 22.0165C51.7678 21.1849 51.2698 20.8202 50.7833 20.8202C50.2948 20.8202 49.7968 21.1849 49.7968 22.0165C49.7968 22.8463 50.2929 23.2035 50.7833 23.2035Z"
        fill="#0055A2"
      />
      <path
        d="M53.4207 20.627C54.0061 20.627 54.2418 20.947 54.2418 21.3228C54.2418 21.5832 54.1221 21.8288 53.7571 21.9497V21.9609C54.0936 22.0242 54.3216 22.2884 54.3216 22.6177C54.3216 23.3916 53.5291 23.399 53.3979 23.399H52.6719V20.627H53.4207ZM52.9361 21.853H53.3846C53.7571 21.853 53.9586 21.6279 53.9586 21.3265C53.9586 20.9897 53.702 20.8521 53.3941 20.8521H52.938V21.853H52.9361ZM52.9361 23.1721H53.3599C53.5975 23.1721 54.0365 23.1293 54.0365 22.6214C54.0365 22.2772 53.7647 22.08 53.3884 22.08H52.9361V23.1721Z"
        fill="#0055A2"
      />
      <path
        d="M56.0743 20.5933C56.9315 20.5933 57.344 21.2705 57.344 22.0147C57.344 22.93 56.7871 23.4286 56.0743 23.4286C55.3616 23.4286 54.8047 22.9319 54.8047 22.0147C54.8028 21.2705 55.2171 20.5933 56.0743 20.5933ZM56.0743 23.2035C56.5628 23.2035 57.0589 22.8463 57.0589 22.0165C57.0589 21.1849 56.5628 20.8202 56.0743 20.8202C55.584 20.8202 55.0879 21.1849 55.0879 22.0165C55.0879 22.8463 55.584 23.2035 56.0743 23.2035Z"
        fill="#0055A2"
      />
      <path
        d="M59.3276 20.627V20.8521H58.229V21.853H59.2763V22.0781H58.229V23.1721H59.4036V23.399H57.9648V20.627H59.3276Z"
        fill="#0055A2"
      />
      <path
        d="M62.0274 20.5933C62.8846 20.5933 63.2971 21.2705 63.2971 22.0147C63.2971 22.93 62.7402 23.4286 62.0274 23.4286C61.3147 23.4286 60.7578 22.9319 60.7578 22.0147C60.7578 21.2705 61.1703 20.5933 62.0274 20.5933ZM62.0274 23.2035C62.5159 23.2035 63.012 22.8463 63.012 22.0165C63.012 21.1849 62.5159 20.8202 62.0274 20.8202C61.5371 20.8202 61.041 21.1849 61.041 22.0165C61.041 22.8463 61.5371 23.2035 62.0274 23.2035Z"
        fill="#0055A2"
      />
      <path
        d="M65.4138 20.627V20.8521H64.1803V21.8381H64.6611C65.4822 21.8381 65.5753 22.3888 65.5753 22.6139C65.5753 23.1218 65.2028 23.399 64.6421 23.399H63.918V20.627H65.4138ZM64.1803 23.1721H64.5889C65.1686 23.1721 65.2902 22.8577 65.2902 22.6139C65.2902 22.3497 65.1534 22.0632 64.5927 22.0632H64.1803V23.1721Z"
        fill="#0055A2"
      />
      <path
        d="M67.5757 20.627V20.8521H66.479V21.853H67.5244V22.0781H66.479V23.1721H67.6517V23.399H66.2148V20.627H67.5757Z"
        fill="#0055A2"
      />
      <path
        d="M70.1781 23.3226C70.066 23.3579 69.7619 23.4305 69.4008 23.4305C68.439 23.4305 68.0703 22.6807 68.0703 22.0556C68.0703 21.4007 68.4086 20.5933 69.4331 20.5933C69.6973 20.5933 69.9215 20.6174 70.1496 20.7012L70.1249 20.9393C69.9291 20.8593 69.684 20.8202 69.4559 20.8202C68.7831 20.8202 68.3554 21.3505 68.3554 22.0593C68.3554 22.69 68.7716 23.2035 69.4407 23.2035C69.6973 23.2035 69.9653 23.157 70.1781 23.0733V23.3226Z"
        fill="#0055A2"
      />
      <path d="M72.6931 20.627V23.399H72.4289V20.8521H70.9673V23.399H70.7031V20.627H72.6931Z" fill="#0055A2" />
      <path
        d="M74.855 20.627V20.8521H73.7564V21.853H74.8036V22.0781H73.7564V23.1721H74.931V23.399H73.4922V20.627H74.855Z"
        fill="#0055A2"
      />
      <path
        d="M75.6353 20.627V21.5442C75.6353 22.0502 76.1485 22.0428 76.3157 22.0428C76.4925 22.0428 76.6692 22.0297 76.846 22.0074L77.0057 21.9888V20.627H77.2698V23.399H77.0057V22.2139L76.846 22.2307C76.6578 22.2493 76.4735 22.2697 76.2834 22.2697C75.4414 22.2697 75.3711 21.799 75.3711 21.5497V20.627H75.6353Z"
        fill="#0055A2"
      />
      <path
        d="M79.4312 20.627V20.8521H78.3345V21.853H79.3799V22.0781H78.3345V23.1721H79.5091V23.399H78.0703V20.627H79.4312Z"
        fill="#0055A2"
      />
      <path
        d="M80.3755 20.627V21.8493H81.8333V20.627H82.0975V23.399H81.8333V22.0744H80.3755V23.399H80.1094V20.627H80.3755Z"
        fill="#0055A2"
      />
      <path
        d="M83.1626 20.627V22.7088L83.1474 23.0456H83.1588L83.3147 22.7646L84.6698 20.627H84.9701V23.399H84.706V21.3153L84.7231 20.9786H84.7098L84.5691 21.239L83.2006 23.399H82.8984V20.627H83.1626Z"
        fill="#0055A2"
      />
      <path
        d="M87.1304 20.627V20.8521H86.0337V21.853H87.081V22.0781H86.0337V23.1721H87.2083V23.399H85.7695V20.627H87.1304Z"
        fill="#0055A2"
      />
    </svg>
  );
}, "GarantLogo");

export const GarantLogo = bundleIcon(Icon, Icon);
