import { useQuery } from "react-query";
import { all as allPublicDocuments } from "@taskpane/plugin/action/documents/all";
import { all as allPrivateDocuments } from "@taskpane/plugin/action/private/documents/all";
import { useAuth } from "@taskpane/auth";
import { getVerificationByDocumentId } from "@taskpane/plugin/service/verification/verifications-store";
import { getId } from "@taskpane/plugin/word/document/get-id";

export function useDocuments() {
  const { isAuthenticated, loading } = useAuth();
  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: "/private/documents",
    queryFn: async () => {
      if (!isAuthenticated) {
        return await allPublicDocuments();
      } else {
        const documentId = await getId();
        const verification = await getVerificationByDocumentId(documentId);
        return await allPrivateDocuments({
          verificationId: verification !== null ? verification.verificationId : undefined,
        });
      }
    },
    enabled: loading === false,
  });
  return { data, isLoading, refetch };
}
