import { useState } from "react";

type TariffVariant = "basic" | "authorized" | "checkdoc";

const tariffOptions: TariffVariant[] = ["basic", "authorized", "checkdoc"];

export function useTariff(initial: TariffVariant = "basic") {
  const [currentTariff, setCurrentTariff] = useState<TariffVariant>(initial);

  const prev = () => {
    const currentIndex = tariffOptions.indexOf(currentTariff);
    if (currentIndex > 0) {
      setCurrentTariff(tariffOptions[currentIndex - 1]);
    }
  };

  const next = () => {
    const currentIndex = tariffOptions.indexOf(currentTariff);
    if (currentIndex < tariffOptions.length - 1) {
      setCurrentTariff(tariffOptions[currentIndex + 1]);
    }
  };

  return {
    currentTariff,
    prev,
    next,
  };
}
