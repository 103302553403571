import React from "react";
import { FilterType } from "@taskpane/plugin/links/hook/use-link-filter/use-link-filter";
import { SearchBox } from "@fluentui/react-components";

export function LinkFilter({
  setFilterCriteria,
}: {
  setFilterCriteria: (type: FilterType, value: string) => void;
}): React.JSX.Element {
  return <SearchBox placeholder="Искать" onChange={(_event, data) => setFilterCriteria("text", data.value)} />;
}
