import React, { useRef } from "react";
import { makeStyles, shorthands } from "@fluentui/react-components";
import { useScrollRestoration } from "@taskpane/shared/scroll-restoration";

type Props = {
  children: React.ReactNode;
};

const useStyles = makeStyles({
  root: {
    height: "100%",
    marginTop: "48px",
    ...shorthands.overflow("hidden", "scroll"),
  },
});

export function Content({ children }: Props): React.JSX.Element {
  const styles = useStyles();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  useScrollRestoration(scrollContainerRef);

  return (
    <div ref={scrollContainerRef} className={styles.root}>
      {children}
    </div>
  );
}
