import React from "react";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

type Props = {
  children: React.ReactNode;
};

const useStyles = makeStyles({
  root: {
    ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXL),
  },
});

export function Container({ children }: Props): React.JSX.Element {
  const styles = useStyles();
  return <div className={styles.root}>{children}</div>;
}
