import { useEffect } from "react";
import { useActions as useSharedActions } from "@taskpane/infrastructure/actions";

export function useActions() {
  const { addAction, removeAction, clearActions } = useSharedActions();
  useEffect(() => {
    return () => {
      clearActions();
    };
  }, []);
  return { addAction, removeAction };
}
