import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, MainLayout, BackNavigation } from "@taskpane/shared/layout";
import Tariffs from "@taskpane/pages/profile/account-level/tariffs";

export default function Page(): React.JSX.Element {
  const navigate = useNavigate();
  return (
    <MainLayout backNavigation={<BackNavigation title={"К профилю"} onClick={() => navigate("/profile")} />}>
      <Container>
        <Tariffs />
      </Container>
    </MainLayout>
  );
}
