import React from "react";
import { MainLayout, Container } from "@taskpane/shared/layout";
import { ControlPanel } from "./control-panel";

export default function Page(): React.JSX.Element {
  return (
    <MainLayout>
      <Container>
        <ControlPanel />
      </Container>
    </MainLayout>
  );
}
