import React from "react";
import { Container, Content, Footer, Layout as LayoutUI } from "@taskpane/shared/layout";
import { LogoHeader } from "@taskpane/pages/login/ui/logo-header";
import { FooterContent } from "@taskpane/pages/login/ui/footer-content";

export function Layout({ children }: { children: React.ReactNode }): React.JSX.Element {
  return (
    <LayoutUI>
      <LogoHeader />
      <Content>
        <Container>{children}</Container>
      </Content>
      <Footer>
        <FooterContent />
      </Footer>
    </LayoutUI>
  );
}
