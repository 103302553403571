import React from "react";
import { bundleIcon, FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

const Icon = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM4 8C4 8.27614 4.22386 8.5 4.5 8.5C9.01621 8.5 7.29954 8.5 11.5 8.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5C7.29954 7.5 9.01621 7.5 4.5 7.5C4.22386 7.5 4 7.72386 4 8Z"
        fill="#B10E1C"
      />
    </svg>
  );
}, "NotAvailable");

export const NotAvailable = bundleIcon(Icon, Icon);
