import React from "react";
import { GarantLogo } from "@taskpane/pages/login/ui/garant-logo";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    height: "48px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F0F5FB",
  },
});

export function LogoHeader() {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <GarantLogo />
    </div>
  );
}
