import { useEffect } from "react";
import { useActions } from "@taskpane/infrastructure/actions";
import { Link } from "../model/link";
import { Links } from "../model/links";

export function useClearAction(links: Links, link: Link | null, clear: () => Promise<void>) {
  const { addAction, removeAction } = useActions();

  useEffect(() => {
    if (links.length > 0 && link === null) {
      addAction({
        id: "clear",
        title: "Очистить всё",
        onClick: async () => {
          await clear();
        },
      });
    } else {
      removeAction("clear");
    }
  }, [links, link]);
}
