import { links } from "./ru/links";
import { documents } from "./ru/documents";
import { fragments } from "./ru/fragments";

type Language = "ru";
type PluralType = "links" | "documents" | "fragments";

interface Plurals {
  [key: string]: {
    [key in PluralType]: Record<Intl.LDMLPluralRule, string>;
  };
}

const plurals: Plurals = {
  ru: {
    links,
    documents,
    fragments,
  },
};

export const getPlurals = (type: PluralType, lang: Language = "ru"): Record<Intl.LDMLPluralRule, string> => {
  const langPlurals = plurals[lang];
  if (!langPlurals) {
    throw new Error(`Unsupported language: ${lang}`);
  }

  const typePlurals = langPlurals[type];
  if (!typePlurals) {
    throw new Error(`Unsupported plural type: ${type}`);
  }

  return typePlurals;
};
