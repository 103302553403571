import { useAuth } from "@taskpane/auth";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Authorization } from "@taskpane/pages/login/ui/authorization";

export default function Page(): React.JSX.Element {
  const { isAuthenticated, signIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/cp");
    }
  }, [isAuthenticated]);

  return <Authorization onLogin={() => signIn()} onSkip={() => navigate("/cp")} />;
}
