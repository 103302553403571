import { useEffect, useState } from "react";
import { Document } from "../model/document";
import { useDocumentFilter } from "@taskpane/plugin/document/hook/use-document-filter";
import { useQueryClient } from "react-query";

export function useDocumentState(data: Document[]) {
  const [documents, setDocuments] = useState<Document[]>(data);
  const [document, setDocumentState] = useState<Document | null>(null);
  const [prevDocument, setPrevDocument] = useState<Document | null>(null);
  const [nextDocument, setNextDocument] = useState<Document | null>(null);
  const { filteredDocuments, setFilterCriteria } = useDocumentFilter(documents);

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries("/analytics");
  }, [documents]);

  useEffect(() => {
    if (data.length > 0) {
      setDocuments(data);
    }
  }, [data]);

  useEffect(() => {
    if (document !== null) {
      const currentIndex = documents.findIndex((existing) => existing.id === document.id);
      setNextDocument(documents[currentIndex - 1] ?? documents[0]);
      setPrevDocument(documents[currentIndex + 1] ?? documents[documents.length - 1]);
    }
  }, [document, documents]);

  const setDocument = (newDocument: Document) => {
    const updatedEntries = newDocument.entries.map((entry) => ({
      ...entry,
      isSelected: false,
      references: entry.references.map((reference) => ({
        ...reference,
        isSelected: false,
      })),
    }));

    const updatedDocument = {
      ...newDocument,
      entries: updatedEntries,
    };

    setDocumentState(updatedDocument);
  };

  const resetDocument = () => {
    setDocumentState(null);
  };

  const goToNextDocument = () => {
    setDocument(nextDocument);
  };

  const goToPrevDocument = () => {
    setDocument(prevDocument);
  };

  const removeEntry = (entryId: string) => {
    if (!document) return;

    const updatedEntries = document.entries.filter((entry) => entry.id !== entryId);

    const updatedDocument = {
      ...document,
      fragmentsCount: document.fragmentsCount - 1,
      entries: updatedEntries,
    };

    setDocumentState(updatedDocument);
    setDocuments(documents.map((doc) => (doc.id === updatedDocument.id ? updatedDocument : doc)));
  };

  const toggleReference = (entryId: string, referenceIndex: number) => {
    if (!document) return;

    const updatedEntries = document.entries.map((entry) => {
      if (entry.id === entryId) {
        const isEntrySelected = entry.isSelected;
        const updatedReferences = entry.references.map((reference, index) => ({
          ...reference,
          isSelected: index === referenceIndex ? !reference.isSelected : false,
        }));

        return {
          ...entry,
          isSelected: !isEntrySelected,
          references: updatedReferences,
        };
      }
      return {
        ...entry,
        isSelected: false,
        references: entry.references.map((reference) => ({
          ...reference,
          isSelected: false,
        })),
      };
    });

    const updatedDocument = {
      ...document,
      entries: updatedEntries,
    };

    setDocumentState(updatedDocument);
    setDocuments(documents.map((doc) => (doc.id === updatedDocument.id ? updatedDocument : doc)));
  };

  const goToNextReference = (entryId: string) => {
    if (!document) return;

    const entry = document.entries.find((entry) => entry.id === entryId);
    if (!entry) return;

    const currentReferenceIndex = entry.references.findIndex((reference) => reference.isSelected);
    const nextReferenceIndex = (currentReferenceIndex + 1) % entry.references.length;

    toggleReference(entryId, nextReferenceIndex);
  };

  const goToPreviousReference = (entryId: string) => {
    if (!document) return;

    const entry = document.entries.find((entry) => entry.id === entryId);
    if (!entry) return;

    const currentReferenceIndex = entry.references.findIndex((reference) => reference.isSelected);
    const previousReferenceIndex = (currentReferenceIndex - 1 + entry.references.length) % entry.references.length;

    toggleReference(entryId, previousReferenceIndex);
  };

  const removeReference = (entryId: string, referenceIndex: number) => {
    if (!document) return;

    const updatedEntries = document.entries.map((entry) => {
      if (entry.id === entryId) {
        const updatedReferences = entry.references.filter((_, index) => index !== referenceIndex);

        return {
          ...entry,
          references: updatedReferences,
        };
      }
      return entry;
    });

    const updatedDocument = {
      ...document,
      linksCount: document.linksCount - 1,
      entries: updatedEntries,
    };

    setDocumentState(updatedDocument);
    setDocuments(documents.map((doc) => (doc.id === updatedDocument.id ? updatedDocument : doc)));
  };

  return {
    documents,
    filteredDocuments,
    setFilterCriteria,
    document,
    setDocument,
    goToNextDocument,
    goToPrevDocument,
    resetDocument,
    removeEntry,
    toggleReference,
    goToNextReference,
    goToPreviousReference,
    removeReference,
  };
}
