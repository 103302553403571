import React from "react";
import { FilterType } from "../hook/use-document-filter/use-document-filter";
import { makeStyles, SearchBox } from "@fluentui/react-components";
import { Filter } from "@taskpane/shared/ui";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "4px",
  },
  searchBox: {
    width: "100%",
  },
  filter: {
    padding: "6px",
  },
});

export function DocumentFilter({
  setFilterCriteria,
}: {
  setFilterCriteria: (type: FilterType, value: string) => void;
}): React.JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <SearchBox
        className={styles.searchBox}
        placeholder="Искать"
        onChange={(_event, data) => setFilterCriteria("title", data.value)}
      />
      <Filter className={styles.filter} fontSize={20} />
    </div>
  );
}
