import { Api } from "@taskpane/shared/word";

type Link = {
  title: string;
  url: string;
};

export async function getAllLinks(): Promise<Link[]> {
  return await Api.run(async (context) => {
    const bodyRange = context.document.body.getRange("Whole");

    const hyperLinks = bodyRange.getHyperlinkRanges();
    hyperLinks.load(["hyperlink", "text"]);
    await context.sync();

    return hyperLinks.items.map((linkRange) => ({
      title: linkRange.text,
      url: linkRange.hyperlink,
    }));
  });
}
