import React from "react";
import { createHashRouter, Navigate } from "react-router-dom";
import ControlPanel from "@taskpane/pages/control-panel/page";
import Analyse from "@taskpane/pages/analyse/page";
import Documents from "@taskpane/pages/documents/page";
import ErrorPage from "@taskpane/pages/error/page";
import LoginPage from "@taskpane/pages/login/page";
import { Layout } from "@taskpane/pages/login/layout";
import VerificationPage from "@taskpane/pages/verificaton/page";
import ProfilePage from "@taskpane/pages/profile/page";
import { PrivateRoutes } from "./private-routes";
import AccountLevelPage from "@taskpane/pages/profile/account-level/page";
import UnknownLinksPage from "@taskpane/pages/unknown-links/page";

export const router = createHashRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to="/login" />,
      },
      {
        path: "login",
        element: (
          <Layout>
            <LoginPage />
          </Layout>
        ),
      },
      {
        path: "cp",
        element: <ControlPanel />,
      },
      {
        path: "analyse",
        element: <Analyse />,
      },
      {
        path: "documents",
        element: <Documents />,
      },
      {
        path: "/",
        element: <PrivateRoutes />,
        children: [
          {
            path: "unknown-links",
            element: <UnknownLinksPage />,
          },
          {
            path: "verification",
            element: <VerificationPage />,
          },
          {
            path: "profile",
            element: <ProfilePage />,
          },
          {
            path: "profile/account-level",
            element: <AccountLevelPage />,
          },
        ],
      },
    ],
  },
]);
