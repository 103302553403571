/**
 * Обрабатывает ошибки, возникающие во время выполнения приложения.
 *
 * @param error - Ошибка, возникшая во время выполнения.
 * @throws {Error} Ошибка, возникшая во время выполнения.
 */
export function errorHandler(error: Error) {
  // eslint-disable-next-line no-undef
  if (error instanceof OfficeExtension.Error) {
    handleOfficeExtensionError(error);
  }
  throw error;
}

/**
 * Обрабатывает ошибки OfficeExtension, выбрасывая новую ошибку с отформатированным сообщением.
 *
 * @param error - Ошибка OfficeExtension для обработки.
 * @throws {Error} Новая ошибка с отформатированным сообщением.
 */
// eslint-disable-next-line no-undef
function handleOfficeExtensionError(error: OfficeExtension.Error) {
  throw new Error(`Во время обработки возникла ошибка. (Code: "${error.code}", Message:"${error.message}")`);
}
