import { updateAllLinks } from "@taskpane/plugin/word/link/update-all-links";

export async function setLinks(
  links: {
    oldUrl: string;
    newUrl: string;
  }[]
): Promise<void> {
  await updateAllLinks(links);
}
