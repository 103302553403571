import { Api } from "@taskpane/shared/word";
import { documentUrlParser } from "@taskpane/plugin/document";

export async function removeByLinkAndIndex(url: string, index: number): Promise<void> {
  return await Api.run(async (context) => {
    const bodyRange = context.document.body.getRange("Whole");

    const hyperLinks = bodyRange.getHyperlinkRanges();
    hyperLinks.load(["hyperlink", "text"]);
    await context.sync();

    const linkRanges = hyperLinks.items.filter((linkRange) => documentUrlParser.isEqual(linkRange.hyperlink, url));

    const linkRange = linkRanges?.[index] ?? null;

    if (linkRange === null) {
      throw new Error("Ссылка не найдена");
    }

    linkRange.hyperlink = undefined;
  });
}
