type DateOffsetType = "DAYS" | "MONTHS" | "YEARS" | "MONTH_START" | "YEAR_START" | "WEEK_START";

interface DateOffset {
  type: DateOffsetType;
  value?: number;
}

export function calculateDate(offset: DateOffset): Date {
  return getDateWithOffset(offset);
}

function getDateWithOffset(offset: DateOffset): Date {
  const date = new Date();

  switch (offset.type) {
    case "DAYS":
      if (offset.value !== undefined) {
        date.setDate(date.getDate() + offset.value);
      }
      break;
    case "MONTHS":
      if (offset.value !== undefined) {
        date.setMonth(date.getMonth() + offset.value);
      }
      break;
    case "YEARS":
      if (offset.value !== undefined) {
        date.setFullYear(date.getFullYear() + offset.value);
      }
      break;
    case "MONTH_START":
      date.setDate(1);
      break;
    case "YEAR_START":
      date.setMonth(0);
      date.setDate(1);
      break;
    case "WEEK_START": {
      const day = date.getDay();
      date.setDate(date.getDate() - day + (day === 0 ? -6 : 1)); // Понедельник как начало недели
      break;
    }
  }

  return date;
}
