import React from "react";
import { bundleIcon, FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

const Icon = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg width="7" height="12" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.646447 0.853553C0.451184 0.658291 0.451184 0.341709 0.646447 0.146447C0.841709 -0.0488155 1.15829 -0.0488155 1.35355 0.146447L5.85355 4.64645C6.04882 4.84171 6.04882 5.15829 5.85355 5.35355L1.35355 9.85355C1.15829 10.0488 0.841709 10.0488 0.646447 9.85355C0.451184 9.65829 0.451184 9.34171 0.646447 9.14645L4.79289 5L0.646447 0.853553Z"
        fill="#424242"
      />
    </svg>
  );
}, "ChevronRight");

export const ChevronRight = bundleIcon(Icon, Icon);
