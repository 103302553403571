import React from "react";
import { makeStyles, tokens } from "@fluentui/react-components";
import { formatter } from "@taskpane/shared/formatter";
import { getPlurals } from "@taskpane/plugin/shared/plurals";

type Props = {
  linksCount: number;
  fragmentsCount: number;
};

const useStyles = makeStyles({
  root: {
    color: tokens.colorNeutralForeground3,
  },
});

export function DocumentInfo({ linksCount, fragmentsCount }: Props): React.JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      {linksCount +
        " " +
        formatter.plural(linksCount, getPlurals("links")) +
        " на " +
        fragmentsCount +
        " " +
        formatter.plural(fragmentsCount, getPlurals("fragments"))}
    </div>
  );
}
