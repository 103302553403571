import React from "react";
import { bundleIcon, FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

const Icon = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7 0C7.55228 0 8 0.447715 8 1C8 1.51284 7.61396 1.93551 7.11662 1.99327L7 2H5C3.34315 2 2 3.34315 2 5C2 6.59768 3.24892 7.90366 4.82373 7.99491L5 8H7C7.55228 8 8 8.44772 8 9C8 9.51284 7.61396 9.93551 7.11662 9.99327L7 10H5C2.23858 10 0 7.76142 0 5C0 2.31125 2.12231 0.118182 4.78311 0.0046196L5 0H7ZM15 0C17.7614 0 20 2.23858 20 5C20 7.68876 17.8777 9.88182 15.2169 9.99538L15 10H13C12.4477 10 12 9.55228 12 9C12 8.48716 12.386 8.06449 12.8834 8.00673L13 8H15C16.6569 8 18 6.65685 18 5C18 3.40232 16.7511 2.09634 15.1763 2.00509L15 2H13C12.4477 2 12 1.55228 12 1C12 0.487164 12.386 0.0644928 12.8834 0.0067277L13 0H15ZM5 4H15C15.5523 4 16 4.44772 16 5C16 5.51284 15.614 5.93551 15.1166 5.99327L15 6H5C4.44772 6 4 5.55228 4 5C4 4.48716 4.38604 4.06449 4.88338 4.00673L5 4H15H5Z"
        fill="currentColor"
      />
    </svg>
  );
}, "LinkIcon");

export const LinkIcon = bundleIcon(Icon, Icon);
