import React from "react";
import { Tariff } from "@taskpane/plugin/ui/tariff";
import { useAuth } from "@taskpane/auth";
import { useTariff } from "@taskpane/pages/profile/account-level/use-tariff";

export default function Tariffs(): React.JSX.Element {
  const { user } = useAuth();
  const userTariff = user.isPremium ? "checkdoc" : "authorized";
  const { currentTariff, prev, next } = useTariff(userTariff);

  return <Tariff tariff={currentTariff} isActive={currentTariff === userTariff} onPrev={prev} onNext={next} />;
}
