import React from "react";
import { useNavigate } from "react-router-dom";
import { ControlPanelLayout, CheckDocIcon, LinkIcon, TrashIcon } from "@taskpane/plugin/ui/control-panel";
import { removeAllLinks } from "@taskpane/plugin/action/control-panel/remove-all-links";
import { useAlertContext } from "@taskpane/infrastructure/alert";
import { useAuth } from "@taskpane/auth";
import { Actions, ActionsItem } from "@taskpane/shared/ui/action";
import { Badge } from "@fluentui/react-components";

export function ControlPanel(): React.JSX.Element {
  const { user } = useAuth();
  const alert = useAlertContext();
  const navigate = useNavigate();
  const isPremium: boolean = user?.isPremium ?? false;
  return (
    <ControlPanelLayout
      actions={
        <Actions>
          <ActionsItem icon={<LinkIcon />} title={"Проставить ссылки"} onClick={() => navigate("/analyse")} />
          <ActionsItem
            icon={<CheckDocIcon />}
            title={"Проверить документы"}
            badge={
              !isPremium && (
                <Badge appearance="tint" shape="rounded">
                  Доступ
                </Badge>
              )
            }
            onClick={() => navigate("/verification")}
          />
          <ActionsItem
            icon={<TrashIcon />}
            title={"Удалить все ссылки"}
            onClick={async () => {
              await removeAllLinks();
              alert.success("Все ссылки были удалены");
            }}
          />
        </Actions>
      }
    />
  );
}
