import React from "react";
import { Card, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { Checkbox } from "@taskpane/shared/ui";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    color: tokens.colorNeutralForeground3,
    ...shorthands.textDecoration("none"),
    ...shorthands.gap("4px"),
  },

  content: {
    display: "flex",
    flexDirection: "column",
    color: tokens.colorNeutralForeground3,
    ...shorthands.textDecoration("none"),
    ...shorthands.gap("8px"),
  },
});

export function ChoiceDocument({
  content,
  selected,
  onSelect,
  status,
}: {
  content: string;
  selected: boolean;
  onSelect: () => void;
  status?: React.ReactElement;
}): React.JSX.Element {
  const styles = useStyles();
  return (
    <Card className={styles.root}>
      <div>
        <Checkbox checked={selected} shape={"circular"} onClick={onSelect} />
      </div>
      <div className={styles.content}>
        {content}
        {status}
      </div>
    </Card>
  );
}
