import React from "react";
import { useActions } from "@taskpane/infrastructure/actions/use-actions";
import { Action, ActionFooter } from "@taskpane/shared/layout";

export function Actions(): React.JSX.Element {
  const { actions } = useActions();

  if (actions.length > 0) {
    return (
      <ActionFooter>
        {actions.map((action, index) => (
          <Action
            key={index}
            variant={index === 0 ? "primary" : "secondary"}
            title={action.title}
            onClick={action.onClick}
          />
        ))}
      </ActionFooter>
    );
  }

  return null;
}
