import React from "react";
import { bundleIcon, FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

export const Icon = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M27.9074 1.14032C28.3822 1.64149 28.3609 2.43266 27.8597 2.90744L8.85969 20.9074C8.61765 21.1367 8.29405 21.2598 7.96081 21.2494C7.62757 21.2389 7.31232 21.0958 7.08514 20.8518L0.335138 13.6018C-0.135284 13.0965 -0.107036 12.3056 0.398232 11.8351C0.903499 11.3647 1.69445 11.393 2.16487 11.8982L8.05567 18.2254L26.1403 1.09256C26.6415 0.617773 27.4327 0.639156 27.9074 1.14032Z"
        fill="currentColor"
      />
    </svg>
  );
}, "Checkmark");

export const Checkmark = bundleIcon(Icon, Icon);
