import { getBodyOoxml } from "@taskpane/plugin/word/content/get-body-ooxml";
import { setBodyOoxml } from "@taskpane/plugin/word/content/set-body-ooxml";
import { verification as verificationApi } from "@taskpane/plugin/api/endpoints/private/verification/verification";
import { getStatus } from "@taskpane/plugin/api/endpoints/private/verification/get-status";
import { getResult } from "@taskpane/plugin/api/endpoints/private/verification/get-result";
import { all as allAnalytics } from "@taskpane/plugin/action/analytics/all";
import { getId } from "@taskpane/plugin/word/document/get-id";

const MAX_REQUESTS_LIMIT = 20;
const INTERVAL_BETWEEN_REQUESTS = 20000;

function awaitResult(verificationId: string) {
  let delay = INTERVAL_BETWEEN_REQUESTS;

  return new Promise((resolve, reject) => {
    let count = 0;
    let timerId = setInterval(async () => {
      count += 1;
      try {
        const statusResult = await getStatus(verificationId);
        if (statusResult.hasError === true) {
          clearInterval(timerId);
          reject("Возникла ошибка во время проверки");
        }
        if (statusResult.active === false) {
          clearInterval(timerId);
          resolve("");
        }
      } catch (error) {
        reject(error.message);
      }
      if (count === MAX_REQUESTS_LIMIT) {
        clearInterval(timerId);
        reject("");
      }
    }, delay);
  });
}

type Result = {
  docsCount: number;
  documentId: string;
  verificationId: string;
};

export async function verification({ dateFrom }: { dateFrom: string }): Promise<Result> {
  const content = await getBodyOoxml();

  const verificationResult = await verificationApi({
    dateFrom: dateFrom,
    content: content,
  });
  await awaitResult(verificationResult.id);
  const result = await getResult(verificationResult.id);

  await setBodyOoxml(result.content);

  const analytics = await allAnalytics();
  const documentId = await getId();

  return {
    docsCount: analytics.docsCount,
    documentId: documentId,
    verificationId: verificationResult.id,
  };
}
