import React from "react";
import { Container, MainLayout } from "@taskpane/shared/layout";
import { Profile } from "./profile";

export default function Page(): React.JSX.Element {
  return (
    <MainLayout>
      <Container>
        <Profile />
      </Container>
    </MainLayout>
  );
}
