import React from "react";
import { bundleIcon, FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

export const Icon = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22.5 24C22.5 24.275 22.276 24.5 22 24.5H16.2678C15.9806 25.051 15.6168 25.5557 15.1904 26H22C23.104 26 24 25.104 24 24V13.828C24 13.298 23.789 12.789 23.414 12.414L17.585 6.586C17.57 6.57105 17.5531 6.55808 17.5363 6.5452C17.5238 6.53567 17.5115 6.5262 17.5 6.516C17.429 6.452 17.359 6.389 17.281 6.336C17.2557 6.31894 17.2281 6.30548 17.2005 6.29207C17.1845 6.28426 17.1685 6.27647 17.153 6.268C17.1363 6.25859 17.1197 6.24897 17.103 6.23933C17.0488 6.20797 16.9944 6.17648 16.937 6.152C16.74 6.07 16.528 6.029 16.313 6.014C16.2933 6.01274 16.2738 6.01008 16.2542 6.00741C16.2271 6.00371 16.1999 6 16.172 6H10C8.896 6 8 6.896 8 8V15.4982C8.47417 15.3004 8.97679 15.1572 9.5 15.0764V8C9.5 7.725 9.724 7.5 10 7.5H16V12C16 13.104 16.896 14 18 14H22.5V24ZM17.5 8.621L21.378 12.5H18C17.724 12.5 17.5 12.275 17.5 12V8.621Z"
        fill="#0E700E"
      />
      <path
        d="M16 21.5C16 24.5376 13.5376 27 10.5 27C7.46243 27 5 24.5376 5 21.5C5 18.4624 7.46243 16 10.5 16C13.5376 16 16 18.4624 16 21.5ZM13.8536 19.1464C13.6583 18.9512 13.3417 18.9512 13.1464 19.1464L9.5 22.7929L7.85355 21.1464C7.65829 20.9512 7.34171 20.9512 7.14645 21.1464C6.95118 21.3417 6.95118 21.6583 7.14645 21.8536L9.14645 23.8536C9.34171 24.0488 9.65829 24.0488 9.85355 23.8536L13.8536 19.8536C14.0488 19.6583 14.0488 19.3417 13.8536 19.1464Z"
        fill="#0E700E"
      />
    </svg>
  );
}, "CheckDoc");

export const CheckDoc = bundleIcon(Icon, Icon);
