import { Url, UrlParser, InvalidUrlError } from "@taskpane/shared/url-parser";

const ALLOW_PATTERN = /^https:\/\/d\.garant\.ru\/document\/(\d+)(?:\/entry\/(\d+))?\/?$/;

export class PathParser implements UrlParser {
  isAllow(url: string): boolean {
    return ALLOW_PATTERN.test(url);
  }

  parse(url: string): Url {
    const match = url.match(ALLOW_PATTERN);

    if (!match) {
      throw new InvalidUrlError("Invalid URL format");
    }

    const documentId = match[1];
    const entryId = match[2] ?? "0";

    return { uuid: `${documentId}.${entryId}` };
  }
}
