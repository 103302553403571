import { Avatar } from "@fluentui/react-components";
import React from "react";
import { useAuth } from "@taskpane/auth";
import { Link } from "react-router-dom";

export function UserMenu(): React.JSX.Element {
  const { user } = useAuth();
  const isPremium: boolean = user?.isPremium ?? false;

  return (
    <Link to="/profile">
      <Avatar
        active={isPremium ? "active" : "unset"}
        name={user?.name ?? ""}
        size={28}
        color={user !== null ? "brand" : "neutral"}
      />
    </Link>
  );
}
