import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { VerificationPeriod } from "@taskpane/plugin/ui/verification";
import { calculateDate } from "@taskpane/plugin/service/calculate-date";
import { StatusScreen } from "@taskpane/plugin/ui/status-screen";
import { verification } from "@taskpane/plugin/action/private/verification/verification";
import { Loader } from "@taskpane/shared/ui";
import { formatter } from "@taskpane/shared/formatter";
import { DocumentChecking } from "@taskpane/plugin/ui/verification";
import { addVerificationData } from "@taskpane/plugin/service/verification/verifications-store";
import { useAuth } from "@taskpane/auth";
import { DocumentErrorRegular } from "@fluentui/react-icons";
import { getPlurals } from "@taskpane/plugin/shared/plurals";
import { useActions } from "@taskpane/infrastructure/actions";
import { Container, MainLayout, BackNavigation } from "@taskpane/shared/layout";

const PERIODS = [
  {
    label: "Последние 7 дней",
    value: calculateDate({ type: "DAYS", value: -7 }),
  },
  {
    label: "Текущий месяц",
    value: calculateDate({ type: "MONTH_START" }),
  },
  {
    label: "Полгода",
    value: calculateDate({ type: "DAYS", value: -183 }),
  },
  {
    label: "Год",
    value: calculateDate({ type: "YEAR_START" }),
  },
  {
    label: "3 года",
    value: calculateDate({ type: "YEARS", value: -3 }),
  },
];

export default function Page(): React.JSX.Element {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { addAction, removeAction, clearActions } = useActions();
  const [period, setPeriod] = useState<Date | undefined>(undefined);
  const [periods] = useState(PERIODS);
  const [checking, setChecking] = useState<boolean>(false);
  const { data = null, isLoading } = useQuery({
    queryKey: "/verification",
    queryFn: async () => await verification({ dateFrom: period.toISOString().split("T")[0] }),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled: checking,
  });

  useEffect(() => {
    if (data !== null) {
      addVerificationData({ documentId: data.documentId, verificationId: data.verificationId });
      addAction({
        id: "open-documents",
        title: "Открыть список документов",
        onClick: () => {
          navigate("/documents");
          removeAction("set-link");
        },
      });
    }
    return () => {
      clearActions();
    };
  }, [data]);

  useEffect(() => {
    if (period !== undefined) {
      addAction({
        id: "check",
        title: "Проверить",
        onClick: () => {
          setChecking(true);
          removeAction("check");
        },
      });
    } else {
      removeAction("check");
    }
  }, [period]);

  if (!user.isPremium) {
    return (
      <MainLayout backNavigation={<BackNavigation title={"К панели управления"} onClick={() => navigate("/")} />}>
        <StatusScreen
          variant="error"
          icon={<DocumentErrorRegular fontSize={30} />}
          title={"У вас нет премиум-подписки"}
          text={"Приобретите премиум-подписку, чтобы проверять документы"}
        />
      </MainLayout>
    );
  }

  if (isLoading) {
    return (
      <MainLayout backNavigation={<BackNavigation title={"К панели управления"} onClick={() => navigate("/")} />}>
        <Loader label={"Выполняется проверка..."} />
      </MainLayout>
    );
  }

  return (
    <MainLayout backNavigation={<BackNavigation title={"К панели управления"} onClick={() => navigate("/")} />}>
      <Container>
        {data === null ? (
          <VerificationPeriod
            current={period}
            periods={periods}
            onChange={(date) => setPeriod((prev) => (date === prev ? undefined : date))}
          />
        ) : (
          <StatusScreen
            variant="success"
            icon={<DocumentChecking />}
            title={"Документы проверены"}
            text={`Проверено ${data.docsCount} ${formatter.plural(data.docsCount, getPlurals("documents"))}`}
          />
        )}
      </Container>
    </MainLayout>
  );
}
