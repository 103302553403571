import React from "react";
import { Analytics as AnalyticsUI, AnalyticItem } from "@taskpane/plugin/ui/analytics";
import { all as allAnalytics } from "@taskpane/plugin/action/analytics/all";
import { useQuery } from "react-query";

export function Analytics() {
  const { data: analytics } = useQuery("/analytics", async () => await allAnalytics());
  return (
    <AnalyticsUI>
      <AnalyticItem variant="primary" title="Всего ссылок" quantity={String(analytics?.linksCount ?? "-")} />
      <AnalyticItem variant="default" title="Всего документов" quantity={String(analytics?.docsCount ?? "-")} />
    </AnalyticsUI>
  );
}
