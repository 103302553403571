import React from "react";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingHorizontalM, tokens.spacingVerticalM),
  },
});

export function Documents({ children }: { children: React.ReactNode }): React.JSX.Element {
  const styles = useStyles();
  return <div className={styles.root}>{children}</div>;
}
