import { useState, useMemo } from "react";
import { Document } from "../../model/document";

export type FilterType = "title";

interface FilterCriteria {
  type: FilterType;
  value: string;
}

export function useDocumentFilter(initialDocuments: Document[]) {
  const [criteria, setCriteria] = useState<FilterCriteria[]>([]);

  const filteredDocuments = useMemo(() => {
    return initialDocuments.filter((document) => {
      return criteria.every((criteria) => {
        if (criteria.type === "title") {
          return String(document.title).toLowerCase().trim().includes(String(criteria.value).toLowerCase().trim());
        } else {
          return true;
        }
      });
    });
  }, [initialDocuments, criteria]);

  const setFilterCriteria = (type: FilterType, value: string) => {
    const updatedCriteria = [...criteria.filter((c) => c.type !== type), { type, value }];
    setCriteria(updatedCriteria);
  };

  const removeFilterCriteria = (type: FilterType) => {
    setCriteria((prev) => prev.filter((criteria) => criteria.type !== type));
  };

  return { filteredDocuments, filterCriteria: criteria, setFilterCriteria, removeFilterCriteria };
}
