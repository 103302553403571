import React from "react";
import { Button, makeStyles, shorthands } from "@fluentui/react-components";

type Props = {
  title: string;
  variant?: "secondary" | "primary" | "outline" | "subtle" | "transparent";
  onClick: () => void;
  disabled?: boolean;
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    ...shorthands.padding("8px", 0),
  },

  button: {
    width: "100%",
    ...shorthands.padding("5px", "12px"),
  },
});

export function Action({ title, variant = "primary", onClick, disabled = false }: Props): React.JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <Button size={"medium"} appearance={variant} className={styles.button} onClick={onClick} disabled={disabled}>
        {title}
      </Button>
    </div>
  );
}
