import React, { createContext, useContext, useState } from "react";

interface ScrollContextType {
  scrollStates: { [key: string]: boolean };
  setScrollState: (path: string, value: boolean) => void;
  saveScrollPosition: (path: string, position: number) => void;
  getScrollPosition: (path: string) => number | null;
}

const ScrollContext = createContext<ScrollContextType | undefined>(undefined);

export const useScrollContext = (): ScrollContextType => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error("useScrollContext must be used within a ScrollProvider");
  }
  return context;
};

export function ScrollProvider({ children }: { children: React.ReactNode }) {
  const [scrollStates, setScrollStates] = useState<{ [key: string]: boolean }>({});
  const [scrollPositions, setScrollPositions] = useState<{ [key: string]: number }>({});

  const setScrollState = (path: string, value: boolean) => {
    setScrollStates((prev) => ({ ...prev, [path]: value }));
  };

  const saveScrollPosition = (path: string, position: number) => {
    setScrollPositions((prev) => ({ ...prev, [path]: position }));
  };

  const getScrollPosition = (path: string): number | null => {
    return scrollPositions[path] ?? null;
  };

  return (
    <ScrollContext.Provider value={{ scrollStates, setScrollState, saveScrollPosition, getScrollPosition }}>
      {children}
    </ScrollContext.Provider>
  );
}
