import React from "react";
import { makeStyles, Button, typographyStyles, shorthands } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  title: {
    textAlign: "center",
    ...typographyStyles.body1Stronger,
    ...shorthands.padding(0, "10px"),
  },

  button: {
    width: "64px",
  },
});

export function Navigation({
  currentTariff,
  onPrev,
  onNext,
}: {
  currentTariff: string;
  onPrev: () => void;
  onNext: () => void;
}): React.JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <Button onClick={onPrev}>Назад</Button>
      <div className={styles.title}>{currentTariff}</div>
      <Button onClick={onNext}>Далее</Button>
    </div>
  );
}
