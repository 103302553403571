import { getAllByIds } from "@taskpane/plugin/api/endpoints/public/documents/get-all-by-ids";
import { getAllLinks } from "@taskpane/plugin/word/link/get-all-links";
import { documentUrlParser } from "@taskpane/plugin/document";

export async function all() {
  const wordLinks = await getAllLinks();
  const ids: string[] = wordLinks
    .filter((link) => documentUrlParser.isAllow(link.url))
    .map((link) => documentUrlParser.parse(link.url).uuid);
  const documents = await getAllByIds(ids);

  return documents.data.map((document) => ({
    ...document,
    entries: document.entries.map((entry) => {
      return {
        ...entry,
        references: wordLinks
          .filter((link) => documentUrlParser.isAllow(link.url) && entry.id === documentUrlParser.parse(link.url).uuid)
          .map((link) => ({
            content: link.title,
            url: link.url,
          })),
      };
    }),
  })) satisfies {
    id: string;
    url: string;
    documentId: number;
    title: string;
    entries: {
      id: string;
      entryId: number;
      title: string | null;
      url: string;
      changed: boolean | null;
      references: {
        content: string;
        url: string;
      }[];
    }[];
    status: string | null;
    changed: boolean | null;
    effectiveDate: string | null;
    expireDate: string | null;
    editDate: string | null;
  }[];
}
