import React from "react";
import { createRoot } from "react-dom/client";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import { RouterProvider } from "react-router-dom";
import { AlertProvider } from "./infrastructure/alert";
import { QueryClientWithAlertsProvider } from "@taskpane/infrastructure/providers/query-client-with-alerts-provider";
import { AuthProvider } from "@taskpane/auth";
import { router } from "@taskpane/infrastructure/router";
import { ActionsProvider } from "./infrastructure/actions";
import { ScrollProvider } from "@taskpane/shared/scroll-restoration";

/* global document, Office, module, require */

const rootElement: HTMLElement = document.getElementById("app");
const root = createRoot(rootElement);

/* Render application after Office initializes */
Office.onReady(() => {
  root.render(
    <FluentProvider theme={webLightTheme}>
      <AlertProvider>
        <AuthProvider>
          <QueryClientWithAlertsProvider>
            <ActionsProvider>
              <ScrollProvider>
                <RouterProvider router={router} />
              </ScrollProvider>
            </ActionsProvider>
          </QueryClientWithAlertsProvider>
        </AuthProvider>
      </AlertProvider>
    </FluentProvider>
  );
});

if ((module as any).hot) {
  (module as any).hot.accept("./pages/control-panel/page", () => {
    const NextApp = require("@taskpane/pages/control-panel/page").default;
    root.render(NextApp);
  });
}
