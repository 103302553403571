import React from "react";
import { useNavigate } from "react-router-dom";
import { Content, Header, Layout as RootLayout } from "@taskpane/shared/layout";
import { Navigation } from "../navigation";
import { Alert } from "@taskpane/infrastructure/alert";
import { Logo } from "../logo";
import { Footer } from "../footer";
import { Actions } from "@taskpane/infrastructure/actions";
import { UserMenu } from "../user-menu";

export function Layout({
  children,
  backNavigation,
}: {
  children: React.ReactNode;
  backNavigation?: React.ReactNode;
}): React.JSX.Element {
  const navigate = useNavigate();

  return (
    <RootLayout>
      <Header>
        <Logo onClick={() => navigate("/")} />
        <Navigation />
        <UserMenu />
      </Header>
      <Content>
        {backNavigation}
        <Alert />
        {children}
      </Content>
      <Footer>
        <Actions />
      </Footer>
    </RootLayout>
  );
}
