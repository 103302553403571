import { api } from "@taskpane/shared/api";

interface ApiResult {
  data: {
    content: string;
  };
}

export async function getResult(analiseId: string) {
  const result = await api.get<unknown, ApiResult>(`/plugin/analysing/${analiseId}/result`);

  return {
    content: result.data.content,
  } satisfies {
    content: string;
  };
}
