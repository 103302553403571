import { api } from "@taskpane/shared/api";

interface ApiResult {
  id: number;
  active: boolean;
}

export async function analysing(content: string) {
  const result = await api.post<never, ApiResult>("/plugin/analysing", {
    format: "ooxml",
    content: content,
  });

  return {
    id: String(result.id),
  } satisfies {
    id: string;
  };
}
