import { useContext } from "react";
import { AuthContext } from "./auth-context";

export type User = {
  id: string;
  name: string;
  lastName: string;
  firstName: string;
  email: string;
  isPremium: boolean;
};
export function useAuth() {
  const context = useContext(AuthContext);

  return context satisfies {
    isAuthenticated: boolean;
    user: User | null;
    token: string;
    loading: boolean;
    error: string | null;
    signIn: () => void;
    signOut: () => void;
  };
}
