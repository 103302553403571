import axios, { type AxiosError, type AxiosInstance, type AxiosResponse, type CreateAxiosDefaults } from "axios";

export const baseUrl: string = "https://checkdoc-plugin.garant.ru/api";

const config: CreateAxiosDefaults = {
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    "X-Mode": "plugin",
  },
};

export const api: AxiosInstance = axios.create(config);

function httpErrorHandler(error: Error | AxiosError | null): void {
  if (error === null) {
    throw new Error("Unrecoverable error! Error is null!");
  }

  if (axios.isAxiosError(error)) {
    const response = error.response;
    if (response == null) {
      throw new Error(error.message);
    }
    throw new Error(JSON.stringify(response));
  }

  throw new Error(error.message);
}

function responseHandler(response: AxiosResponse): AxiosResponse {
  const status = response.status;
  if (status >= 200 && status <= 299) {
    return response?.data;
  }
  throw new Error("Invalid status code");
}

function responseErrorHandler(error: AxiosError): void {
  httpErrorHandler(error);
}
api.interceptors.request.use(async (request) => {
  const token = localStorage.getItem("ROCP_token");
  if (token !== "") {
    request.headers.Authorization = `Bearer ${token}`;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return request;
});

api.interceptors.response.use(responseHandler, responseErrorHandler);
