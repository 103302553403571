import React from "react";
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";
import { ArrowLeftFilled, ArrowRightFilled } from "@fluentui/react-icons";
import { truncate } from "@taskpane/shared/truncator";
import { MoreDialog } from "@taskpane/plugin/ui/fragment";

type Props = {
  title: string;
  onPrev: () => void;
  onNext: () => void;
};

const TITLE_LENGTH = 45;

const useStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.borderWidth(0, 0, "1px", 0),
    ...shorthands.borderStyle("solid"),
    ...shorthands.borderColor(tokens.colorNeutralStroke2),
    ...shorthands.gap(tokens.spacingVerticalS),
    ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXL),
  },

  container: {
    display: "flex",
    height: "57px",
    justifyContent: "space-between",
    alignItems: "center",
    ...shorthands.gap(tokens.spacingVerticalS),
    ...shorthands.padding(0, tokens.spacingHorizontalS),
  },

  contentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "end",
    ...shorthands.gap(tokens.spacingVerticalXXS),
  },

  contentContainerIcon: {
    cursor: "pointer",
  },

  title: {
    textAlign: "center",
    ...typographyStyles.body1Strong,
  },
});

export function DocumentNavigation({ title, onPrev, onNext }: Props): React.JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.contentContainerIcon} onClick={onPrev}>
          <ArrowLeftFilled />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.title}>{truncate(title, TITLE_LENGTH)}</div>
          <div>{title.length > TITLE_LENGTH && <MoreDialog content={title} />}</div>
        </div>
        <div className={styles.contentContainerIcon} onClick={onNext}>
          <ArrowRightFilled />
        </div>
      </div>
    </div>
  );
}
