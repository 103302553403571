import React from "react";
import { Info } from "@taskpane/shared/ui";
import { StatusScreen } from "@taskpane/plugin/ui/status-screen";

export function EmptyState(): React.JSX.Element {
  return (
    <StatusScreen
      variant="info"
      icon={<Info />}
      title={"Ссылки не проставлены"}
      text={"Чтобы увидеть список ссылок, запустите их простановку"}
    />
  );
}
