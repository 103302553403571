import { Url, UrlParser, InvalidUrlError } from "@taskpane/shared/url-parser";

const ALLOW_PATTERN = /^https:\/\/base\.garant\.ru\/(\d+)\/?([\w-]+)?$/;

export class PathParser implements UrlParser {
  isAllow(url: string): boolean {
    return ALLOW_PATTERN.test(url);
  }

  parse(url: string): Url {
    const match = url.match(ALLOW_PATTERN);

    if (!match) {
      throw new InvalidUrlError("Invalid URL format");
    }

    return { uuid: `${match[1]}.0` };
  }
}
