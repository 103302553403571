import React from "react";
import { bundleIcon, FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

const Icon = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.35435 11.8527C6.15944 12.0484 5.84285 12.0489 5.64724 11.854L0.162761 6.38902C-0.0529522 6.17408 -0.0529512 5.82477 0.162761 5.60982L5.64725 0.14484C5.84285 -0.0500734 6.15944 -0.0495088 6.35435 0.1461C6.54926 0.34171 6.5487 0.658292 6.35309 0.853206L1.18851 5.99942L6.35309 11.1456C6.5487 11.3406 6.54926 11.6571 6.35435 11.8527Z"
        fill="#424242"
      />
    </svg>
  );
}, "ChevronLeft");

export const ChevronLeft = bundleIcon(Icon, Icon);
