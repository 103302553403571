import { Api } from "@taskpane/shared/word";

export async function selectByText(text: string, selectIndex = 1, subText: string | null = null): Promise<void> {
  return await Api.run(async (context) => {
    const results = context.document.body.search(text);
    results.load();

    await context.sync();

    const current = results.items[selectIndex - 1];

    if (subText !== null) {
      const subCurrent = current.search(subText, {
        ignorePunct: true,
        ignoreSpace: true,
      });
      subCurrent.load();
      await context.sync();
      subCurrent.getFirst().select();
    } else {
      current.select();
    }
  });
}
