import { useState, useEffect } from "react";

interface Timer {
  timerId: number;
  delay: number;
  isRunning: boolean;
  timeLeft: number;
  cancelText?: string;
}

interface UseTimer {
  createTimer: (id: string, callback: () => void, delay: number) => void;
  cancel: (id: string) => void;
  timers: { [id: string]: Timer };
}

export function useTimer() {
  const [timers, setTimers] = useState<{ [id: string]: Timer }>({});

  const createTimer = (id: string, callback: () => void, delay: number, cancelText: string = "Отменить") => {
    if (delay > 0) {
      const timerId = setTimeout(() => {
        callback();
        removeTimer(id);
      }, delay);
      setTimers((prevTimers) => ({
        ...prevTimers,
        [id]: { timerId: +timerId, delay, isRunning: true, timeLeft: delay, cancelText },
      }));
    }
  };

  const stopTimer = (id: string) => {
    const timer = timers[id];
    if (timer) {
      clearTimeout(timer.timerId);
    }
  };

  const removeTimer = (id: string) => {
    stopTimer(id);
    setTimers((prevTimers) => {
      const newTimers = { ...prevTimers };
      delete newTimers[id];
      return newTimers;
    });
  };

  const cancel = (id: string) => {
    removeTimer(id);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimers((prevTimers) => {
        const newTimers = { ...prevTimers };
        Object.keys(newTimers).forEach((id) => {
          const timer = newTimers[id];
          if (timer.isRunning) {
            timer.timeLeft -= 1000;
            if (timer.timeLeft <= 0) {
              removeTimer(id);
            }
          }
        });
        return newTimers;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return { createTimer, cancel, timers } satisfies UseTimer;
}
