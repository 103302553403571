import { Button } from "@fluentui/react-components";
import React, { useRef } from "react";
import { Document, DocumentContent, DocumentFooter, DocumentInfo, DocumentMoreDialog } from "@taskpane/plugin/document";
import { truncate } from "@taskpane/shared/truncator";
import { useTimer } from "@taskpane/shared/hook";
import { OverlayTimer } from "@taskpane/shared/ui";

const MAX_TITLE_LENGTH = 255;
const DELETION_DELAY_MS = 5000;

export function DocumentItem({
  title,
  status,
  linksCount,
  fragmentsCount,
  onShowFragments,
  onRemove,
}: {
  title: string;
  status?: React.JSX.Element;
  linksCount: number;
  fragmentsCount: number;
  onShowFragments: () => void;
  onRemove: () => void;
}): React.JSX.Element {
  const timerId = useRef(Math.random().toString()).current;
  const { createTimer, cancel, timers } = useTimer();

  const handleRemove = () => {
    createTimer(timerId, onRemove, DELETION_DELAY_MS);
  };

  return (
    <OverlayTimer
      delay={DELETION_DELAY_MS}
      time={timers[timerId]?.timeLeft ?? 0}
      isRunning={timers[timerId]?.isRunning ?? false}
      onCancel={() => cancel(timerId)}
      actionText={"Вернуть ссылку на документ"}
    >
      <Document>
        <DocumentContent>
          <div>{truncate(title, MAX_TITLE_LENGTH)}</div>
          {title.length > MAX_TITLE_LENGTH && (
            <div style={{ alignSelf: "end" }}>
              <DocumentMoreDialog content={title} />
            </div>
          )}
        </DocumentContent>
        {status}
        <DocumentInfo linksCount={linksCount} fragmentsCount={fragmentsCount} />
        <DocumentFooter>
          <Button appearance="secondary" onClick={onShowFragments}>
            Показать фрагменты
          </Button>
          <Button appearance="subtle" onClick={handleRemove}>
            Удалить документ
          </Button>
        </DocumentFooter>
      </Document>
    </OverlayTimer>
  );
}
