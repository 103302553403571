import React from "react";
import {
  makeStyles,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  shorthands,
  tokens,
  typographyStyles,
} from "@fluentui/react-components";
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    height: "48px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    ...shorthands.padding(0, tokens.spacingHorizontalXL),
    ...shorthands.gap("8px"),
  },
  menu: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    ...shorthands.gap("8px"),
  },
  menuTitle: {
    ...typographyStyles.body1Strong,
  },
});

export function Navigation(): React.JSX.Element {
  const styles = useStyles();
  const location = useLocation();

  const menuItems = [
    { label: "Панель управления", url: "/cp" },
    { label: "Уточнение ссылок", url: "/unknown-links" },
    { label: "Список документов", url: "/documents" },
    { label: "Профиль", url: "/profile" },
  ];

  const controlPanelItems = [
    { label: "Простановка ссылок", url: "/analyse" },
    { label: "Проверка документов", url: "/verification" },
    { label: "Уровень аккаунта", url: "/profile/account-level" },
  ];

  const title =
    menuItems.find((item) => item.url === location.pathname)?.label ??
    controlPanelItems.find((item) => item.url === location.pathname)?.label ??
    "Главная";

  return (
    <div className={styles.root}>
      <div className={styles.menu}>
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <MenuButton appearance="transparent">{title}</MenuButton>
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              {menuItems.map((item) => (
                <Link key={item.url} to={item.url} style={{ textDecoration: "none" }}>
                  <MenuItem>{item.label}</MenuItem>
                </Link>
              ))}
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>
    </div>
  );
}
