import { getAllLinks } from "@taskpane/plugin/word/link/get-all-links";
import { documentUrlParser } from "@taskpane/plugin/document";
import { getAllByIds } from "@taskpane/plugin/api/endpoints/public/documents/get-all-by-ids";
import { unknownUrlParser } from "@taskpane/plugin/links";

type Result = {
  linksCount: number;
  unknownLinksCount: number;
  docsCount: number;
};

export async function all(): Promise<Result> {
  const wordLinks = await getAllLinks();
  const documentLinks = wordLinks.filter((link) => documentUrlParser.isAllow(link.url));
  const documentIds: string[] = documentLinks.map((link) => documentUrlParser.parse(link.url).uuid);
  const { data: documents } = await getAllByIds(documentIds);

  const unknownLinksIds: string[] = wordLinks
    .filter((link) => unknownUrlParser.isAllow(link.url))
    .map((link) => unknownUrlParser.parse(link.url).uuid);

  return {
    linksCount: documentIds.length,
    unknownLinksCount: Array.from(new Set(unknownLinksIds)).length,
    docsCount: Array.from(new Set(documents.map((document) => document.id))).length,
  };
}
