import { Url, UrlParser, InvalidUrlError } from "@taskpane/shared/url-parser";

export class CompositeUrlParser implements UrlParser {
  private readonly parsers: UrlParser[];

  constructor(parsers: UrlParser[]) {
    this.parsers = parsers;
  }

  isAllow(url: string): boolean {
    return this.parsers.some((parser) => parser.isAllow(url));
  }

  parse(url: string): Url {
    for (const parser of this.parsers) {
      if (parser.isAllow(url)) {
        return parser.parse(url);
      }
    }

    throw new InvalidUrlError("No parser allowed the URL");
  }
}
