import React from "react";
import { bundleIcon, FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

export const Icon = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.98847 0.000135658H4.01153C4.00378 -4.56856e-05 3.99604 -4.47525e-05 3.98833 0.000135658H2C1.81062 0.000135658 1.63749 0.107137 1.55279 0.276529L0.0527893 3.27653C-0.0319067 3.44592 -0.0136288 3.64863 0.100003 3.80014L4.59309 9.79092C4.60879 9.8129 4.62613 9.83338 4.64488 9.85227C4.69214 9.9 4.74968 9.93882 4.81561 9.96491C4.86671 9.98521 4.92129 9.99718 4.97718 9.99974C4.98969 10.0003 5.00216 10.0004 5.01458 10.0001C5.07316 9.99835 5.13042 9.98628 5.1839 9.9651C5.24957 9.93919 5.30695 9.90066 5.35414 9.85326C5.37327 9.8341 5.39094 9.81328 5.40691 9.79092L9.9 3.80014C10.0136 3.64863 10.0319 3.44592 9.94722 3.27653L8.44722 0.276529C8.36252 0.107137 8.18939 0.000135658 8 0.000135658H6.01167C6.00396 -4.47525e-05 5.99623 -4.56856e-05 5.98847 0.000135658ZM5.63962 1.00014L6.30629 3.00014H3.69372L4.36038 1.00014H5.63962ZM7.36038 3.00014L6.69372 1.00014H7.69099L8.69099 3.00014H7.36038ZM6.30629 4.00014L5 7.919L3.69372 4.00014H6.30629ZM6.44869 6.73522L7.36038 4.00014H8.5L6.44869 6.73522ZM3.55132 6.73522L1.5 4.00014H2.63962L3.55132 6.73522ZM2.63962 3.00014H1.30902L2.30902 1.00014H3.30629L2.63962 3.00014Z"
        fill="currentColor"
      />
    </svg>
  );
}, "Diamond");

export const Diamond = bundleIcon(Icon, Icon);
