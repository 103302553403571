import React from "react";
import { makeStyles, typographyStyles } from "@fluentui/react-components";

type Props = {
  children: React.ReactNode;
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    ...typographyStyles.body1Strong,
  },
});

export function DocumentContent({ children }: Props): React.JSX.Element {
  const styles = useStyles();
  return <div className={styles.root}>{children}</div>;
}
