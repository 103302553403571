import React from "react";
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    color: tokens.colorBrandForegroundLink,
    backgroundColor: "#EEF5F8",
    ...shorthands.borderWidth("1px"),
    ...shorthands.borderStyle("solid"),
    ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
    ...shorthands.textDecoration("none"),
    ...shorthands.gap("8px"),
    ...shorthands.borderColor("#7BC8ED"),
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
  },

  title: {
    color: "#1D5F7B",
    ...typographyStyles.caption1Strong,
    ...shorthands.padding(0, tokens.spacingHorizontalSNudge),
  },

  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: tokens.colorBrandForegroundLink,
    ...typographyStyles.body1,
    ...shorthands.padding(0, tokens.spacingHorizontalSNudge),
  },
});

export function ChoiceSelected({ content }: { content: string }): React.JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.title}>Ваш выбор:</div>
      <div className={styles.content}>
        <div>{content}</div>
      </div>
    </div>
  );
}
