import React from "react";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "column",
    gap: "16px",
  },
});

export function TariffBlock({ children }: { children: React.ReactNode }): React.JSX.Element {
  const styles = useStyles();
  return <div className={styles.root}>{children}</div>;
}
