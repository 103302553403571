import React from "react";
import { bundleIcon, FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

const Icon = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 2C6.79086 2 5 3.79086 5 6C5 8.20914 6.79086 10 9 10C11.2091 10 13 8.20914 13 6C13 3.79086 11.2091 2 9 2ZM6 6C6 4.34315 7.34315 3 9 3C10.6569 3 12 4.34315 12 6C12 7.65685 10.6569 9 9 9C7.34315 9 6 7.65685 6 6ZM4.00873 11C2.90315 11 2 11.8869 2 13C2 14.6912 2.83281 15.9663 4.13499 16.7966C5.41697 17.614 7.14526 18 9 18C9.41085 18 9.8155 17.9811 10.2105 17.9427C9.97298 17.6472 9.7654 17.3266 9.59233 16.9855C9.39798 16.9951 9.20041 17 9 17C7.26489 17 5.74318 16.636 4.67262 15.9534C3.62226 15.2837 3 14.3088 3 13C3 12.4467 3.44786 12 4.00873 12L9.59971 12C9.7826 11.6422 10.0035 11.3071 10.2572 11L4.00873 11ZM10.6351 13.9199C11.723 13.642 12.369 12.5231 12.0657 11.442L11.9095 10.8852C12.1636 10.6899 12.439 10.5226 12.7314 10.3877L13.0688 10.7457C13.8577 11.5828 15.1885 11.5835 15.9783 10.7472L16.303 10.4034C16.6008 10.5439 16.8806 10.7182 17.1378 10.9215L17.0116 11.3438C16.6823 12.4459 17.3483 13.598 18.4677 13.8627L18.8167 13.9452C18.8383 14.127 18.8494 14.3121 18.8494 14.4999C18.8494 14.6559 18.8417 14.81 18.8268 14.9619L18.3662 15.0795C17.2783 15.3575 16.6323 16.4764 16.9356 17.5575L17.0917 18.1142C16.8376 18.3095 16.5623 18.4769 16.2698 18.6118L15.9325 18.2538C15.1436 17.4167 13.8128 17.416 13.023 18.2523L12.698 18.5964C12.4002 18.4559 12.1205 18.2817 11.8633 18.0785L11.9897 17.6557C12.319 16.5536 11.653 15.4014 10.5336 15.1368L10.184 15.0541C10.1625 14.8725 10.1514 14.6875 10.1514 14.4999C10.1514 14.3438 10.159 14.1896 10.174 14.0377L10.6351 13.9199ZM15.5004 14.4999C15.5004 13.9476 15.0527 13.4999 14.5004 13.4999C13.9481 13.4999 13.5004 13.9476 13.5004 14.4999C13.5004 15.0521 13.9481 15.4999 14.5004 15.4999C15.0527 15.4999 15.5004 15.0521 15.5004 14.4999Z"
        fill="#424242"
      />
    </svg>
  );
}, "Profile");

export const Profile = bundleIcon(Icon, Icon);
