import React from "react";
import { makeStyles, Popover, shorthands, Spinner, typographyStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.padding("16px"),
  },

  popover: {
    backgroundColor: "#fff",
    position: "fixed",
    top: "302px",
    right: 0,
    bottom: 0,
    left: 0,
    height: "112px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ...shorthands.padding("16px"),
    ...shorthands.margin("20px"),
  },

  content: {
    ...typographyStyles.subtitle1,
    ...shorthands.padding("8px", 0, 0, 0),
  },
});

export function Loader({ label = "Загрузка..." }: { label?: string }): React.JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.popover}>
        <Popover open={true} size={"medium"}>
          <Spinner size={"medium"}></Spinner>
          <div className={styles.content}>{label}</div>
        </Popover>
      </div>
    </div>
  );
}
