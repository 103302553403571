import React from "react";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { Available, NotAvailable } from "@taskpane/plugin/ui/tariff/icons";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
    ...shorthands.borderWidth(0, 0, "1px", 0),
    ...shorthands.borderStyle("solid"),
    ...shorthands.borderColor(tokens.colorNeutralStroke3),
  },
});

export function Option({ children, available }: { children: React.ReactNode; available: boolean }): React.JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div>{children}</div>
      <div>{available ? <Available /> : <NotAvailable />}</div>
    </div>
  );
}
