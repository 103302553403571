import React from "react";
import { Card, makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    gap: "4px",
  },
});
export function Analytics({ children }: { children: React.ReactNode }) {
  const styles = useStyles();
  return <Card className={styles.root}>{children}</Card>;
}
