import { InvalidUrlError, Url, UrlParser, UrlParsingError } from "@taskpane/shared/url-parser";

export class UuidParamParser implements UrlParser {
  private readonly ALLOWED_HOST = "d.garant.ru";
  private readonly ALLOWED_PATH_START = "/document/";

  private isValidUuid(uuid: string): boolean {
    if (uuid.length === 0) {
      return false;
    }

    const [documentId, entityId] = uuid.split(".");

    return !!documentId && !!entityId && !isNaN(Number(documentId)) && !isNaN(Number(entityId));
  }

  isAllow(url: string): boolean {
    try {
      const parsedUrl = new URL(url);
      const uuidParam = parsedUrl.searchParams.get("uuid");
      return (
        parsedUrl.hostname === this.ALLOWED_HOST &&
        parsedUrl.pathname.startsWith(this.ALLOWED_PATH_START) &&
        this.isValidUuid(uuidParam)
      );
    } catch (e) {
      return false;
    }
  }

  parse(url: string): Url {
    if (!this.isAllow(url)) {
      throw new InvalidUrlError();
    }

    const parsedUrl = new URL(url);
    const uuid = parsedUrl.searchParams.get("uuid");

    if (!uuid) {
      throw new UrlParsingError("UUID is missing in the URL");
    }

    return {
      uuid,
    };
  }
}
