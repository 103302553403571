import React from "react";
import { useNavigate } from "react-router-dom";
import { Badge } from "@fluentui/react-components";
import { Actions, ActionsItem } from "@taskpane/shared/ui/action";
import { UserProfile } from "@taskpane/plugin/ui/profile/user-profile";
import { useAuth } from "@taskpane/auth";

export function Profile(): React.JSX.Element {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const isPremium: boolean = user?.isPremium ?? false;

  return (
    <div>
      <Actions>
        <UserProfile user={user} onLogout={signOut} />
        <ActionsItem title={"Версия проставляемых ссылок"} badge={"Интернет-версия"} />
        <ActionsItem
          title={"Уровень аккаунта"}
          badge={
            <Badge appearance={isPremium ? "filled" : "outline"} shape="rounded">
              {isPremium ? "ЧекДок Премиум" : "Плагин"}
            </Badge>
          }
          onClick={() => navigate("/profile/account-level")}
        />
        <ActionsItem title={"Обратная связь"} onClick={() => window.open("mailto:checkdoc@garant.ru")} />
        <ActionsItem title={"О системе"} />
      </Actions>
    </div>
  );
}
