import { Api } from "@taskpane/shared/word";

export async function removeAllLinks(): Promise<void> {
  return await Api.run(async (context) => {
    const bodyRange = context.document.body.getRange("Whole");

    const hyperLinks = bodyRange.getHyperlinkRanges();
    hyperLinks.load("hyperlink");
    await context.sync();

    hyperLinks.items.forEach((linkRange) => {
      linkRange.hyperlink = undefined;
    });
  });
}
