import React from "react";
import { CardFooter, makeStyles, shorthands, tokens } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingHorizontalM, tokens.spacingVerticalM),
  },
});

export function DocumentFooter({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}): React.JSX.Element {
  const styles = useStyles();
  return <CardFooter className={styles.root}>{children}</CardFooter>;
}
