import React from "react";
import { shorthands } from "@fluentui/react-components";
import { makeStyles, tokens } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.borderWidth(0, 0, "1px", 0),
    ...shorthands.borderStyle("solid"),
    ...shorthands.borderColor(tokens.colorNeutralStrokeAlpha),
    ...shorthands.padding(0, tokens.spacingHorizontalXL),
  },
});

export function Header({ children }: { children: React.ReactNode }): React.JSX.Element {
  const styles = useStyles();
  return <div className={styles.root}>{children}</div>;
}
