import React from "react";
import { bundleIcon, FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

export const Icon = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.5 14C9.77614 14 10 13.7761 10 13.5C10 13.2239 9.77614 13 9.5 13H3C1.89543 13 1 12.1046 1 11V3C1 1.89543 1.89543 1 3 1H9.5C9.77614 1 10 0.776142 10 0.5C10 0.223858 9.77614 0 9.5 0H3C1.34315 0 0 1.34315 0 3V11C0 12.6569 1.34315 14 3 14H9.5ZM10.6464 3.14645C10.8417 2.95118 11.1583 2.95118 11.3536 3.14645L14.8536 6.64645C15.0488 6.84171 15.0488 7.15829 14.8536 7.35355L11.3536 10.8536C11.1583 11.0488 10.8417 11.0488 10.6464 10.8536C10.4512 10.6583 10.4512 10.3417 10.6464 10.1464L13.2929 7.5H4.5C4.22386 7.5 4 7.27614 4 7C4 6.72386 4.22386 6.5 4.5 6.5H13.2929L10.6464 3.85355C10.4512 3.65829 10.4512 3.34171 10.6464 3.14645Z"
        fill="currentColor"
      />
    </svg>
  );
}, "Logout");

export const Logout = bundleIcon(Icon, Icon);
