import React, { useState } from "react";

const AlertContext = React.createContext<{
  type: Type | null;
  message: string;
  description: string | undefined | null;
  success: (message: string, description?: string, timeout?: number) => void;
  info: (message: string, description?: string, timeout?: number) => void;
  error: (message: string, description?: string, timeout?: number) => void;
  clear: () => void;
} | null>(null);
AlertContext.displayName = "AlertContext";

const enum Type {
  Success = "success",
  Info = "info",
  Error = "error",
}

const DEFAULT_TIME_TIMER = 5000;

const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const [type, setType] = useState<Type | null>(null);
  const [message, setMessage] = useState(null);
  const [alertDescription, setAlertDescription] = useState(null);

  return (
    <AlertContext.Provider
      value={{
        type: type,
        message: message,
        description: alertDescription,
        success: (message: string, description?: string, timeout?: number) => {
          setType(Type.Success);
          setMessage(message);
          setAlertDescription(description ?? null);
          setTimeout(
            () => {
              setType(null);
            },
            timeout * 1000 || DEFAULT_TIME_TIMER
          );
        },
        info: (message: string, description?: string, timeout?: number) => {
          setType(Type.Info);
          setMessage(message);
          setAlertDescription(description ?? null);
          setTimeout(
            () => {
              setType(null);
            },
            timeout * 1000 || DEFAULT_TIME_TIMER
          );
        },
        error: (message: string, description?: string, timeout?: number) => {
          setType(Type.Error);
          setMessage(message);
          setAlertDescription(description ?? null);
          setTimeout(
            () => {
              setType(null);
            },
            timeout * 1000 || DEFAULT_TIME_TIMER
          );
        },
        clear: () => setType(null),
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export { AlertProvider, AlertContext };
