import { Api } from "@taskpane/shared/word";
import { documentUrlParser } from "@taskpane/plugin/document";

export async function remove(url: string): Promise<void> {
  return await Api.run(async (context) => {
    const bodyRange = context.document.body.getRange("Whole");

    const hyperLinks = bodyRange.getHyperlinkRanges();
    hyperLinks.load("hyperlink");
    await context.sync();

    hyperLinks.items.forEach((linkRange) => {
      if (documentUrlParser.isEqual(linkRange.hyperlink, url)) {
        linkRange.hyperlink = undefined;
      }
    });
  });
}
