import React from "react";
import { makeStyles, tokens, shorthands, typographyStyles } from "@fluentui/react-components";

type Props = {
  children: React.ReactNode;
};

const useStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralBackground1,
    ...typographyStyles.body1Strong,
    ...shorthands.borderWidth("1px", 0, 0, 0),
    ...shorthands.borderStyle("solid"),
    ...shorthands.borderColor(tokens.colorNeutralStroke2),
    ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXL),
  },
});

export function ActionFooter({ children }: Props): React.JSX.Element {
  const styles = useStyles();
  return <div className={styles.root}>{children}</div>;
}
