import { api } from "@taskpane/shared/api";

export interface ApiResult {
  active: boolean;
  hasError: boolean;
}

export async function getStatus(analyzeId: string) {
  const result = await api.get<unknown, ApiResult>(`/plugin/analysing/${analyzeId}`);

  return {
    active: result.active,
    hasError: result.hasError,
  } satisfies {
    active: boolean;
    hasError: boolean;
  };
}
