import { Api } from "@taskpane/shared/word";

export async function getBodyOoxml(): Promise<string> {
  return await Api.run(async (context) => {
    const body = context.document.body;
    const bodyOOXML = body.getOoxml();
    await context.sync();
    return bodyOOXML.value satisfies string;
  });
}
