import { Url, UrlParser } from "@taskpane/shared/url-parser";

const ALLOW_PATTERN = /^https:\/\/[a-zA-Z0-9.-]+\.garant\.ru\/proxy\/service\/search\?context=.*&uuid=([a-f0-9-]+)$/;
const UUID_PATTERN = /uuid=([a-f0-9-]+)/;

export class MainParser implements UrlParser {
  isAllow(url: string): boolean {
    return ALLOW_PATTERN.test(url);
  }

  parse(url: string): Url {
    const match = url.match(UUID_PATTERN);
    if (!match) {
      throw new Error("UUID not found in URL");
    }

    const uuid = match[1];
    return {
      uuid,
    };
  }
}
