import React from "react";
import { makeStyles, mergeClasses, shorthands, tokens, typographyStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.borderWidth("1px"),
    ...shorthands.borderStyle("solid"),
    ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
    ...shorthands.textDecoration("none"),
    ...shorthands.gap("8px"),
  },

  rootSquare: {
    padding: "10px 8px",
    height: "40px",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.borderWidth("1px", 0),
  },

  rootRounded: {
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
  },

  title: {
    ...typographyStyles.caption1Strong,
    ...shorthands.padding(0, tokens.spacingHorizontalSNudge),
  },

  titleActive: {
    color: tokens.colorStatusSuccessForeground3,
  },

  titleEdited: {
    color: tokens.colorStatusWarningForeground3,
  },

  titleUpdated: {
    color: tokens.colorStatusWarningForeground3,
  },

  titleExpired: {
    color: tokens.colorStatusDangerForeground3,
  },

  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: tokens.colorNeutralForeground3,
    ...typographyStyles.caption2Strong,
    ...shorthands.padding(0, tokens.spacingHorizontalSNudge),
  },

  active: {
    backgroundColor: tokens.colorStatusSuccessBackground1,
    ...shorthands.borderColor(tokens.colorStatusSuccessBorder1),
  },

  edited: {
    backgroundColor: tokens.colorStatusWarningBackground1,
    ...shorthands.borderColor(tokens.colorStatusWarningBorder1),
  },

  updated: {
    backgroundColor: tokens.colorStatusWarningBackground1,
    ...shorthands.borderColor(tokens.colorStatusWarningBorder1),
  },

  expired: {
    backgroundColor: tokens.colorStatusDangerBackground1,
    ...shorthands.borderColor(tokens.colorStatusDangerBorder1),
  },
});

export function DocumentStatus({
  variant,
  shape = "rounded",
  expireDate,
  editDate,
}: {
  variant: "active" | "edited" | "updated" | "expired";
  shape?: "square" | "rounded";
  expireDate?: string;
  editDate?: string;
}): React.JSX.Element {
  const styles = useStyles();

  const Title = {
    active: () => <>{"Действует"}</>,
    edited: () => <>{"Действует/изменился"}</>,
    updated: () => <>{"Изменился"}</>,
    expired: ({ expireDate }: { expireDate?: string }) => (
      <>{expireDate !== undefined && expireDate !== null ? `Утратил силу с ${expireDate}` : `Утратил силу`}</>
    ),
  }[variant];

  return (
    <div
      className={mergeClasses(
        styles.root,
        {
          active: styles.active,
          edited: styles.edited,
          updated: styles.updated,
          expired: styles.expired,
        }[variant],
        {
          square: styles.rootSquare,
          rounded: styles.rootRounded,
        }[shape]
      )}
    >
      <div
        className={mergeClasses(
          styles.title,
          {
            active: styles.titleActive,
            edited: styles.titleEdited,
            updated: styles.titleUpdated,
            expired: styles.titleExpired,
          }[variant]
        )}
      >
        <Title expireDate={expireDate} />
      </div>
      {editDate && (
        <div className={styles.content}>
          <div>Дата последнего изменения:</div>
          <div>{editDate}</div>
        </div>
      )}
    </div>
  );
}
