import { Api } from "@taskpane/shared/word";
import { v4 as uuidv4 } from "uuid";

export async function getId(): Promise<string> {
  return await Api.run(async (context) => {
    const customProperties = context.document.properties.customProperties;
    customProperties.load("items");

    await context.sync();

    const uniqueIdProperty = customProperties.getItemOrNullObject("DocumentId");
    uniqueIdProperty.load("value");
    await context.sync();

    if (uniqueIdProperty.isNullObject) {
      const uniqueId = uuidv4();
      customProperties.add("DocumentId", uniqueId);
      await context.sync();
      return uniqueId;
    } else {
      return uniqueIdProperty.value;
    }
  });
}
