import React from "react";
import { bundleIcon, FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

const Icon = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2443 9.86523L27.2443 22.1668L7.96801 22.1667V22.0907C15.6061 21.4563 22.1326 16.9065 25.5352 10.4538H25.3939C22.9197 14.13 18.7186 16.5503 13.9518 16.5503C9.18506 16.5503 4.98394 14.13 2.50978 10.4538H1.3464L1.34638 22.1668H0.757812V9.86525H2.82753C5.17141 13.5313 9.27779 15.9617 13.9518 15.9617C18.6259 15.9617 22.7323 13.5313 25.0761 9.86525L27.2443 9.86523Z"
        fill="#E70000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9656 15.9128C16.749 15.9128 19.0054 13.6564 19.0054 10.873C19.0054 8.08964 16.749 5.83325 13.9656 5.83325C11.1822 5.83325 8.92578 8.08964 8.92578 10.873C8.92578 13.6564 11.1822 15.9128 13.9656 15.9128ZM9.6049 10.823C9.86143 10.0633 10.2559 9.29299 10.7861 8.56317C11.3594 7.77412 12.0267 7.12139 12.7276 6.62752L12.7148 6.60615C10.9221 7.14862 9.61059 8.82736 9.58814 10.821L9.6049 10.823Z"
        fill="#0362A6"
      />
    </svg>
  );
}, "GarantLogo");

export const GarantLogo = bundleIcon(Icon, Icon);
