import { useEffect, useState } from "react";
import { Links } from "../model/links";

export function useLinks(initialLinks: Links) {
  const [links, setLinks] = useState<Links>(initialLinks);

  const clearLinks = () => {
    setLinks([]);
  };

  useEffect(() => {
    if (initialLinks.length > 0) {
      setLinks(initialLinks);
    }
  }, [initialLinks]);

  return { links, setLinks, clearLinks };
}
