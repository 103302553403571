import React from "react";
import { makeStyles } from "@fluentui/react-components";
import { LinkList } from "@taskpane/plugin/links/ui/link-list";
import { FilterType } from "@taskpane/plugin/links/hook/use-link-filter/use-link-filter";
import { LinkFilter } from "@taskpane/plugin/links/ui/link-filter";
import { Links } from "../model/links";
import { SelectedDocument } from "../model/selected-document";
import { Link } from "../model/link";
import { Info } from "@taskpane/shared/ui";
import { StatusScreen } from "@taskpane/plugin/ui/status-screen";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
});

export function FilteredLinkList({
  links,
  documents,
  chooseLink,
  removeLink,
  setFilterCriteria,
}: {
  links: Links;
  documents: SelectedDocument[];
  chooseLink: (link: Link) => void;
  removeLink: (link: Link) => void;
  setFilterCriteria: (type: FilterType, value: string) => void;
}): React.JSX.Element {
  const styles = useStyles();
  if (links.length === 0) {
    return (
      <StatusScreen
        variant="info"
        icon={<Info />}
        title={"Отлично!"}
        text={"В вашем документе отсутствуют ссылки, соответствующие нескольким ормативным актам"}
      />
    );
  }
  return (
    <div className={styles.root}>
      <LinkFilter setFilterCriteria={setFilterCriteria} />
      <LinkList links={links} documents={documents} chooseLink={chooseLink} removeLink={removeLink} />
    </div>
  );
}
