import React from "react";
import { makeStyles } from "@fluentui/react-components";
import { Links } from "../model/links";
import { SelectedDocument } from "../model/selected-document";
import { Link } from "@taskpane/plugin/links/model/link";
import { ChoiceSelected } from "./choice-selected";
import { ChoiceLink } from "./choice-link";

const useStyles = makeStyles({
  list: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "12px",
  },
});

export function LinkList({
  links,
  documents,
  chooseLink,
  removeLink,
}: {
  links: Links;
  documents: SelectedDocument[];
  chooseLink: (link: Link) => void;
  removeLink: (link: Link) => void;
}): React.JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.list}>
      {links.map((link) => {
        const existing = documents.find((existing) => existing.oldUrl === link.url) ?? null;
        return (
          <ChoiceLink
            key={link.id}
            content={link.text}
            status={existing !== null ? <ChoiceSelected content={existing.newUrlTitle} /> : undefined}
            onSelect={() => chooseLink(link)}
            onRemove={() => removeLink(link)}
          />
        );
      })}
    </div>
  );
}
