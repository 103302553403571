import React from "react";
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";
import { ChevronLeft } from "@taskpane/shared/ui";

type Props = {
  title: string;
  onClick?: () => void;
};

const useStyles = makeStyles({
  root: {
    height: "48px",
    display: "flex",
    alignItems: "center",
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.padding(0, tokens.spacingHorizontalXL),
    ...shorthands.borderWidth(0, 0, "1px", 0),
    ...shorthands.borderStyle("solid"),
    ...shorthands.borderColor(tokens.colorNeutralStrokeAlpha),
  },

  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    cursor: "pointer",
    ...shorthands.gap(tokens.spacingVerticalS),
    ...shorthands.padding("5px", 0),
  },

  icon: {
    width: "20px",
    height: "20px",
    display: "flex",
    flexShrink: 0,
    alignItems: "center",
    justifyContent: "center",
  },

  title: {
    color: tokens.colorNeutralForeground2LinkHover,
    ...typographyStyles.body1Strong,
  },
});

export function BackNavigation({ title, onClick }: Props): React.JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.container} onClick={onClick}>
        <div className={styles.icon}>
          <ChevronLeft width={10} height={10} />
        </div>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
}
