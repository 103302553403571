import React from "react";
import { Avatar, Link, makeStyles, tokens, typographyStyles, Button } from "@fluentui/react-components";
import { Logout } from "./icons/logout";

interface User {
  firstName: string;
  lastName: string;
  email: string;
}

const useStyles = makeStyles({
  profile: {
    display: "flex",
    flexDirection: "column",
    gap: `${tokens.spacingHorizontalM} ${tokens.spacingVerticalM}`,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  profileName: {
    ...typographyStyles.body1,
  },
  profileEmail: {
    ...typographyStyles.caption1,
  },
  link: {
    marginLeft: "auto",
  },
});

export function UserProfile({ user, onLogout }: { user: User; onLogout: () => void }) {
  const styles = useStyles();
  return (
    <div className={styles.profile}>
      <Link as="a" className={styles.link} onClick={onLogout}>
        <Button appearance="subtle" icon={<Logout />}>
          Выйти
        </Button>
      </Link>
      <Avatar name={[user.lastName, user.firstName].join(" ")} size={36} color={"brand"} />
      <div>
        <div className={styles.profileName}>{[user.lastName, user.firstName].join(" ")}</div>
        <div className={styles.profileEmail}>{user.email}</div>
      </div>
    </div>
  );
}
