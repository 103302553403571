import React from "react";
import { Button, makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";
import { CalendarStrings, DatePicker, defaultDatePickerStrings } from "@fluentui/react-datepicker-compat";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },

  title: {
    ...typographyStyles.body1Stronger,
    ...shorthands.padding(0, 0, tokens.spacingVerticalL, 0),
  },

  periods: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "12px",
  },
});

type Period = {
  label: string;
  value: Date;
};

const localizedStrings: CalendarStrings = {
  ...defaultDatePickerStrings,
  days: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
  shortDays: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  months: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ],

  shortMonths: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
  goToToday: "Перейти к сегодня",
};

const onFormatDate = (date?: Date): string => {
  return !date
    ? ""
    : `${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1).toString().padStart(2, "0")}.${date.getFullYear()}`;
};

export function VerificationPeriod({
  current,
  periods,
  onChange,
}: {
  current?: Date;
  periods: Period[];
  onChange: (date: Date) => void;
}): React.JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.title}>Укажите период документа</div>
      <div className={styles.periods}>
        {periods.map(({ label, value }) => (
          <Button
            key={value.toISOString()}
            onClick={() => onChange(value)}
            appearance={current === value ? "primary" : "outline"}
          >
            {label}
          </Button>
        ))}
        <div>
          <DatePicker
            value={current}
            placeholder="Вручную"
            inlinePopup
            showMonthPickerAsOverlay
            strings={localizedStrings}
            formatDate={onFormatDate}
            onSelectDate={(date) => onChange(date)}
          />
        </div>
      </div>
    </div>
  );
}
