import React from "react";
import { Card, makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";
import { ChevronRight } from "./icons";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px",
    padding: "8px",
  },

  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap(tokens.spacingVerticalSNudge),
  },

  contentIcon: {
    width: "32px",
    height: "32px",
    display: "flex",
    flexShrink: 0,
    alignItems: "center",
    justifyContent: "center",
  },

  contentTitle: {
    ...typographyStyles.body1Strong,
    ...shorthands.padding(tokens.spacingVerticalXS, 0),
  },

  contentDescription: typographyStyles.caption2Strong,
});

export function ActionsItem({
  icon,
  title,
  badge = undefined,
  onClick,
}: {
  icon?: React.ReactElement;
  title: string;
  badge?: React.ReactNode;
  onClick?: () => void;
}): React.JSX.Element {
  const styles = useStyles();
  return (
    <Card className={styles.root} onClick={onClick}>
      <div className={styles.content}>
        {icon && <div className={styles.contentIcon}>{icon}</div>}
        <div>
          <div className={styles.contentTitle}>{title}</div>
          {badge && <div className={styles.contentDescription}>{badge}</div>}
        </div>
      </div>
      <div>
        <ChevronRight />
      </div>
    </Card>
  );
}
