import { createContext } from "react";
import { IAuthContext } from "react-oauth2-code-pkce";

export type User = {
  id: string;
  name: string;
  lastName: string;
  firstName: string;
  email: string;
  isPremium: boolean;
};

type AuthContextType = {
  isAuthenticated: boolean;
  user: User | null;
  token: string;
  loading: boolean;
  error: string | null;
  signIn: IAuthContext["logIn"];
  signOut: () => void;
};

export const AuthContext = createContext<AuthContextType | null>(null);
