import React from "react";
import { bundleIcon, FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

const Icon = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg width="2" height="21" viewBox="0 0 2 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <line x1="1" y1="2.18552e-08" x2="0.999999" y2="21" stroke="#D1D1D1" />
    </svg>
  );
}, "LineIcon");

export const LineIcon = bundleIcon(Icon, Icon);
