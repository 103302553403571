import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Container, MainLayout, BackNavigation } from "@taskpane/shared/layout";
import { useActions } from "@taskpane/infrastructure/actions";
import { analyse } from "@taskpane/plugin/action/analyse/analyse";
import { Loader } from "@taskpane/shared/ui";
import { Checkmark } from "@taskpane/plugin/ui/status-screen/icons";
import { formatter } from "@taskpane/shared/formatter";
import { getPlurals } from "@taskpane/plugin/shared/plurals";
import { StatusScreen } from "@taskpane/plugin/ui/status-screen";
import { all as allAnalytics } from "@taskpane/plugin/action/analytics/all";

export default function Page(): React.JSX.Element {
  const navigate = useNavigate();
  const { addAction, clearActions } = useActions();
  const { isSuccess } = useQuery({
    queryKey: "/analyse/analyse",
    queryFn: async () => await analyse(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const { data: result = null } = useQuery({
    queryKey: "/all-analytics",
    queryFn: async () => await allAnalytics(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled: isSuccess,
    onSuccess: (result) => {
      if (result.unknownLinksCount > 0) {
        addAction({
          id: "unknown-links",
          title: "Уточнить",
          onClick: () => navigate("/unknown-links"),
        });
      }
      addAction({
        id: "open-documents",
        title: "Открыть список документов",
        onClick: () => navigate("/documents"),
      });
    },
  });

  useEffect(() => {
    return () => {
      clearActions();
    };
  }, []);

  if (result === null) {
    return (
      <MainLayout backNavigation={<BackNavigation title={"К панели управления"} onClick={() => navigate("/")} />}>
        <Loader label={"Ставим ссылки..."} />
      </MainLayout>
    );
  }

  const { linksCount, docsCount, unknownLinksCount } = result;
  const linksText = `${linksCount} ${formatter.plural(linksCount, getPlurals("links"))}`;
  const docsText = `${docsCount} ${formatter.plural(docsCount, getPlurals("documents"))}`;
  const hasUnknownLinks = unknownLinksCount > 0;

  return (
    <MainLayout>
      <Container>
        <StatusScreen
          variant="success"
          icon={<Checkmark />}
          title={"Ссылки проставлены"}
          text={`Проставлено ${linksText} на ${docsText}`}
          additionalText={hasUnknownLinks ? "Внимание: для некоторых документов требуется уточнение ссылок" : null}
        />
      </Container>
    </MainLayout>
  );
}
