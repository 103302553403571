import React from "react";
import { useRouteError } from "react-router-dom";
import { Layout } from "@taskpane/shared/layout";

export default function Page(): React.JSX.Element {
  const error = useRouteError() as { statusText?: string; message: string };
  return (
    <Layout>
      <div>
        <h1>Ошибка</h1>
        <p>К сожалению, произошла непредвиденная ошибка.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      </div>
    </Layout>
  );
}
