import React from "react";
import { Button, MessageBar, MessageBarActions, MessageBarBody, MessageBarTitle } from "@fluentui/react-components";
import { useAlertContext } from "@taskpane/infrastructure/alert/use-alert-context";

export function Alert() {
  const alert = useAlertContext();

  if (alert.type === null) {
    return null;
  } else {
    return (
      <MessageBar intent={alert.type} shape={"square"}>
        <MessageBarBody>
          <MessageBarTitle>{alert.message}</MessageBarTitle>
          {alert?.description && <div>{alert?.description}</div>}
        </MessageBarBody>
        <MessageBarActions>
          <Button onClick={() => alert.clear()}>Закрыть</Button>
        </MessageBarActions>
      </MessageBar>
    );
  }
}
