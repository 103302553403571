import React from "react";
import { Card, makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";

type Props = {
  children: React.ReactElement[];
  selected?: boolean;
  disabled?: boolean;
};

const useStyles = makeStyles({
  root: {
    ...shorthands.textDecoration("none"),
  },

  selected: {
    backgroundColor: "#EEF5F8",
    ...shorthands.borderWidth("1px"),
    ...shorthands.borderStyle("solid"),
    ...shorthands.borderColor("#7BC8ED"),
  },

  disabled: {
    color: "rgba(0, 0, 0, 0.4)",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
});

export function Document({ children, selected = false, disabled = false }: Props): React.JSX.Element {
  const styles = useStyles();
  return (
    <Card className={mergeClasses(styles.root, selected ? styles.selected : false, disabled ? styles.disabled : false)}>
      {children}
    </Card>
  );
}
