import React from "react";
import { bundleIcon, FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

export const Icon = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.25 0C7.66421 0 8 0.335786 8 0.75C8 1.12656 7.72249 1.4383 7.36083 1.49187L7.25 1.5H5C3.067 1.5 1.5 3.067 1.5 5C1.5 6.86396 2.95707 8.3876 4.79435 8.49406L5 8.5H7.25C7.66421 8.5 8 8.83579 8 9.25C8 9.62656 7.72249 9.9383 7.36083 9.99187L7.25 10H5C2.23858 10 0 7.76142 0 5C0 2.32226 2.10496 0.136152 4.75045 0.00611925L5 0H7.25ZM15 0C17.7614 0 20 2.23858 20 5C20 5.83806 19.7938 6.62796 19.4294 7.32174C19.0316 7.01938 18.5978 6.76182 18.1357 6.55658C18.3689 6.08773 18.5 5.55918 18.5 5C18.5 3.13604 17.0429 1.6124 15.2057 1.50594L15 1.5H12.75C12.3358 1.5 12 1.16421 12 0.75C12 0.373442 12.2775 0.0617003 12.6392 0.00813198L12.75 0H15ZM5 4.25H15C15.4142 4.25 15.75 4.58579 15.75 5C15.75 5.3797 15.4678 5.69349 15.1018 5.74315L15 5.75H5C4.58579 5.75 4.25 5.41421 4.25 5C4.25 4.6203 4.53215 4.30651 4.89823 4.25685L5 4.25ZM21 12.5C21 9.46243 18.5376 7 15.5 7C12.4624 7 10 9.46243 10 12.5C10 15.5376 12.4624 18 15.5 18C18.5376 18 21 15.5376 21 12.5ZM16.0006 13L16.0011 15.5035C16.0011 15.7797 15.7773 16.0035 15.5011 16.0035C15.225 16.0035 15.0011 15.7797 15.0011 15.5035L15.0006 13H12.4956C12.2197 13 11.9961 12.7762 11.9961 12.5C11.9961 12.2239 12.2197 12 12.4956 12H15.0005L15 9.49927C15 9.22313 15.2239 8.99927 15.5 8.99927C15.7761 8.99927 16 9.22313 16 9.49927L16.0005 12H18.4966C18.7725 12 18.9961 12.2239 18.9961 12.5C18.9961 12.7762 18.7725 13 18.4966 13H16.0006Z"
        fill="#0F6CBD"
      />
    </svg>
  );
}, "Link");

export const Link = bundleIcon(Icon, Icon);
