import { Api } from "@taskpane/shared/word";

export async function setBodyOoxml(content: string): Promise<void> {
  return await Api.run(async (context) => {
    const body = context.document.body;

    await context.sync();

    body.insertOoxml(content, "Replace");
  });
}
