import { PathParser as BasePathParser } from "./base/path-parser/path-parser";
import { UuidParamParser as BaseUuidParamParser } from "./base/uuid-param-parser/uuid-param-parser";
import { PathParser as PremiumPathParser } from "./premium/path-parser/path-parser";
import { UuidParamParser as PremiumUuidParamParser } from "./premium/uuid-param-parser/uuid-param-parser";
import { CompositeUrlParser } from "@taskpane/shared/url-parser";

const baseUuidParamParser = new BaseUuidParamParser();
const basePathParser = new BasePathParser();
const premiumUuidParser = new PremiumUuidParamParser();
const premiumPathParser = new PremiumPathParser();

const parser = new CompositeUrlParser([baseUuidParamParser, basePathParser, premiumUuidParser, premiumPathParser]);

export function isAllow(url: string): boolean {
  return parser.isAllow(url);
}

export function isEqual(firstUrl: string, secondUrl: string): boolean {
  return isAllow(firstUrl) && isAllow(secondUrl) && parse(firstUrl).uuid === parse(secondUrl).uuid;
}

export function isEqualByDocument(firstUrl: string, secondUrl: string): boolean {
  return isAllow(firstUrl) && isAllow(secondUrl) && parse(firstUrl).documentId === parse(secondUrl).documentId;
}

export function parse(url: string) {
  const parsedUrl = parser.parse(url);

  const [documentId, entityId] = parsedUrl.uuid.split(".");

  return {
    uuid: parsedUrl.uuid,
    documentId: Number(documentId),
    entityId: Number(entityId ?? 0),
  } satisfies {
    uuid: string;
    documentId: number;
    entityId: number;
  };
}
