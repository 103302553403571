import { RefObject, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useScrollContext } from "./scroll-restoration-provider";

export function useScrollRestoration(scrollContainerRef: RefObject<HTMLDivElement>): void {
  const { pathname } = useLocation();
  const { scrollStates, saveScrollPosition, getScrollPosition } = useScrollContext();

  const shouldSaveScroll = scrollStates[pathname] !== false;

  function handleScroll() {
    if (shouldSaveScroll && scrollContainerRef.current && scrollContainerRef.current.scrollTop !== 0) {
      saveScrollPosition(pathname, scrollContainerRef.current.scrollTop);
    }
  }

  useEffect(() => {
    const currentRef = scrollContainerRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = getScrollPosition(pathname);
    }
  }, [pathname, shouldSaveScroll, scrollStates]);
}
