import { Api } from "@taskpane/shared/word";

export async function linkByText({ text, url }: { text: string; url: string }): Promise<void> {
  return await Api.run(async (context) => {
    const results = context.document.body.search(text);

    results.load("text");

    await context.sync();

    results.getFirst().hyperlink = url;
  });
}
