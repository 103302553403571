import { api } from "@taskpane/shared/api";

interface ApiResult {
  id: number;
  active: boolean;
}

export async function verification({ dateFrom, content }: { dateFrom: string; content: string }) {
  const result = await api.post<never, ApiResult>("/private/plugin/verification", {
    format: "ooxml",
    dateFrom: dateFrom,
    content: content,
  });

  return {
    id: String(result.id),
  } satisfies {
    id: string;
  };
}
