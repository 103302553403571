import React from "react";
import { bundleIcon, FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

const Icon = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.3684 0V9.14286C17.3684 10.826 18.7823 12.1905 20.5263 12.1905H30V27.4286C30 29.1117 28.5862 30.4762 26.8421 30.4762H16.0901C17.8598 28.6965 18.9474 26.2804 18.9474 23.619C18.9474 18.1488 14.3524 13.7143 8.68421 13.7143C7.28542 13.7143 5.95199 13.9843 4.73684 14.4734V3.04762C4.73684 1.36447 6.15068 0 7.89474 0H17.3684Z"
        fill="#0E700E"
      />
      <path
        d="M19.7368 0.761905V9.14286C19.7368 9.56364 20.0903 9.90476 20.5263 9.90476H29.2105L19.7368 0.761905Z"
        fill="#0E700E"
      />
      <path
        d="M17.3684 23.619C17.3684 28.2477 13.4804 32 8.68421 32C3.88805 32 0 28.2477 0 23.619C0 18.9904 3.88805 15.2381 8.68421 15.2381C13.4804 15.2381 17.3684 18.9904 17.3684 23.619ZM13.9793 20.0327C13.671 19.7351 13.1711 19.7351 12.8628 20.0327L7.10526 25.5892L4.50561 23.0803C4.1973 22.7828 3.69743 22.7828 3.38913 23.0803C3.08082 23.3778 3.08082 23.8603 3.38913 24.1578L6.54702 27.2054C6.85533 27.503 7.3552 27.503 7.66351 27.2054L13.9793 21.1102C14.2876 20.8126 14.2876 20.3302 13.9793 20.0327Z"
        fill="#0E700E"
      />
    </svg>
  );
}, "DocumentChecking");

export const DocumentChecking = bundleIcon(Icon, Icon);
