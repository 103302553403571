import { getAllByIds } from "@taskpane/plugin/api/endpoints/private/links/get-all-by-ids";
import { getAllLinks } from "@taskpane/plugin/word/link/get-all-links";
import { unknownUrlParser } from "@taskpane/plugin/links";

export async function getPrivateLinks() {
  const wordLinks = await getAllLinks();
  const linkIds: string[] = wordLinks
    .filter((link) => unknownUrlParser.isAllow(link.url))
    .map((link) => unknownUrlParser.parse(link.url).uuid);

  if (linkIds.length === 0) {
    return [];
  }

  const links = await getAllByIds(linkIds);

  return links.data satisfies {
    id: string;
    url: string;
    text: string;
    documents: Array<{
      id: string;
      documentId: number;
      title: string;
      url: string;
      status: "ACTIVE" | "NO_ACTIVE";
      changed: boolean | null;
      effectiveDate: string | null;
      expireDate: string | null;
      editDate: string | null;
    }>;
  }[];
}
