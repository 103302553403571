import React from "react";
import { GarantLogo } from "@taskpane/shared/ui";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    cursor: "pointer",
  },
});

export function Logo({ onClick }: { onClick?: () => void }): React.JSX.Element {
  const styles = useStyles();
  return <GarantLogo className={styles.root} onClick={onClick} />;
}
