import React from "react";
import { ProgressBar, Button } from "@fluentui/react-components";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(203, 200, 200, 0.8)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    borderRadius: "4px",
    overflow: "hidden",
    zIndex: 1000,
  },
  action: {
    margin: "10px",
  },
});

export function OverlayTimer({
  children,
  delay,
  time,
  isRunning,
  actionText,
  onCancel,
}: {
  children: React.ReactNode;
  delay: number;
  time: number;
  isRunning: boolean;
  actionText: string;
  onCancel: () => void;
}): React.JSX.Element {
  const styles = useStyles();

  return (
    <div style={{ position: "relative" }}>
      {children}
      {isRunning && (
        <div className={styles.overlay}>
          <Button className={styles.action} appearance="secondary" onClick={onCancel}>
            {actionText}
          </Button>
          <ProgressBar shape="rounded" thickness="large" max={delay} value={time} />
        </div>
      )}
    </div>
  );
}
