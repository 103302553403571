import React from "react";
import { Button as FluentButton, makeStyles, shorthands } from "@fluentui/react-components";

type Props = {
  children: string;
  variant?: "primary" | "secondary" | "subtle" | "outline";
  onClick?: () => void;
};

const useStyles = makeStyles({
  action: {
    width: "100%",
    ...shorthands.padding("6px", "8px"),
  },
});

export function Button({ children, variant = "primary", onClick }: Props): React.JSX.Element {
  const styles = useStyles();
  return (
    <FluentButton className={styles.action} size={"small"} appearance={variant} onClick={onClick}>
      {children}
    </FluentButton>
  );
}
