import { useEffect } from "react";
import { useActions } from "@taskpane/infrastructure/actions";
import { Link } from "../model/link";
import { SelectedDocument } from "../model/selected-document";

export function useConfirmAction(documents: SelectedDocument[], link: Link | null, confirm: () => Promise<void>) {
  const { addAction, removeAction } = useActions();

  useEffect(() => {
    if (documents.length > 0 && link === null) {
      addAction({
        id: "confirm",
        title: "Подтвердить выбор",
        onClick: async () => {
          await confirm();
        },
      });
    } else {
      removeAction("confirm");
    }
  }, [documents, link]);
}
