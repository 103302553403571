import React from "react";
import { bundleIcon, FluentIconsProps, wrapIcon } from "@fluentui/react-icons";

const Icon = wrapIcon((props: FluentIconsProps) => {
  return (
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 0.5C0 0.223858 0.223858 0 0.5 0H9.5C9.77614 0 10 0.223858 10 0.5C10 0.776142 9.77614 1 9.5 1H0.5C0.223858 1 0 0.776142 0 0.5ZM2 3.5C2 3.22386 2.22386 3 2.5 3H7.5C7.77614 3 8 3.22386 8 3.5C8 3.77614 7.77614 4 7.5 4H2.5C2.22386 4 2 3.77614 2 3.5ZM4 6C3.72386 6 3.5 6.22386 3.5 6.5C3.5 6.77614 3.72386 7 4 7H6C6.27614 7 6.5 6.77614 6.5 6.5C6.5 6.22386 6.27614 6 6 6H4Z"
        fill="#424242"
      />
    </svg>
  );
}, "Filter");

export const Filter = bundleIcon(Icon, Icon);
