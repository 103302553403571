import React from "react";
import { formatter } from "@taskpane/shared/formatter";
import { DocumentStatus as SharedDocumentStatus } from "@taskpane/shared/ui/document-status";

type Document = {
  status?: string | null;
  changed?: boolean | null;
  expireDate?: string | null;
};

export function DocumentStatus({
  document,
  shape,
}: {
  document: Document;
  shape?: "square" | "rounded";
}): React.JSX.Element {
  if (document.status === "NO_ACTIVE" && document.expireDate !== null) {
    return (
      <SharedDocumentStatus
        variant={"expired"}
        shape={shape}
        expireDate={formatter.date(new Date(document.expireDate), "dd.MM.yyyy")}
      />
    );
  }
  if (document.status === "ACTIVE" && document.changed === true) {
    return <SharedDocumentStatus variant={"edited"} shape={shape} />;
  }
  if (document.status === "ACTIVE" && (document.changed === false || document.changed === null)) {
    return <SharedDocumentStatus variant={"active"} shape={shape} />;
  }
  return undefined;
}
